import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { isLoggedIn } from '@core/store/auth/auth.selectors';
import { select, Store } from '@ngrx/store';
import { AppState } from '@store/reducers';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class GuestComponent implements OnInit {

  isLoggedIn$: Observable<boolean>;

  constructor(
    private store: Store<AppState>,
    private router: Router
  ) { }

  ngOnInit() {
    this.isLoggedIn$ = this.store.pipe(select(isLoggedIn));

    this.router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (event.url.startsWith('/content')) {
          window.scroll(0, 0);
        }
      }
    });
  }

}
