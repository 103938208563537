import { NgModule, ModuleWithProviders } from '@angular/core';
import { SignupGuard } from '@core/guards/signup.guard';
import { AuthRoutingModule } from './auth-routing.module';
import { SignupComponent } from './subcomponents/signup/signup.component';
import { LoginComponent } from './subcomponents/login/login.component';
import { SharedModule } from '@shared/shared.module';
import { ForgotPasswordComponent } from './subcomponents/forgot-password/forgot-password.component';
import { AuthHttpService } from '@core/services/auth-http.service';
import { ForgotPasswordHttpService } from '@core/services/forgot-password-http.service';
import { AuthGuard } from '@core/guards/auth.guard';
import { AuthEffects } from '@core/store/auth/auth.effects';
import { EffectsModule } from '@ngrx/effects';
import { ViewsModule } from '@views/views.module';
import { AuthComponent } from './auth.component';
import { StoreModule } from '@ngrx/store';
import { authReducer } from '@core/store/auth/auth.reducers';

const components = [
  LoginComponent,
  SignupComponent,
  ForgotPasswordComponent,
  AuthComponent
];

@NgModule({
  declarations: [
    ...components,
  ],
  exports: [
    ...components
  ],
  imports: [
    SharedModule,
    ViewsModule,
    AuthRoutingModule,
    StoreModule.forFeature('auth', authReducer),
    EffectsModule.forFeature([AuthEffects]),
  ],
  providers: [
    AuthHttpService,
    ForgotPasswordHttpService,
    AuthGuard,
    SignupGuard
  ]
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
  return {
    ngModule: AuthModule,
    providers: [
      AuthHttpService,
      AuthGuard,
    ]
  };
  }
}
