import { EventEmitterService } from './event-emitter.service';
import { tap, map } from 'rxjs/operators';
import { Pagination } from './../models/pagination.model';
import { PortalMessage } from './../models/portal-message.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class PortalMessageHttpService {

    pagination: Pagination;
    messages$ = new Subject<any[]>();
    isSuccess: boolean;

    constructor(private http: HttpClient , private eventEmitterService: EventEmitterService) { }

    getWithQuery(pageParam: string): Observable<PortalMessage[]>{
      return this.http.get<ApiResponse>(`/message${pageParam}`).pipe(
        tap(res => this.paginationInit(res)),
        map(res => res.data.rows)
      );
    }

    getUnread(): Observable<any>{
      return this.http.get<ApiResponse>(`/message/unread`).pipe(
        tap(res => {
          if (res.data.row.new_messages > 0){
            this.eventEmitterService.onReloadUnreadMessag();
          }
        }),
        map(res => res.data.row.new_messages)
      );
    }

    updateRead(data: any){
      return this.http.post<ApiResponse>(`/message/read`, data);
    }

    private paginationInit(res: any) {
      if (res) {
        this.pagination = res.data.paginations;
      }
    }

}
