import { NumberVerificationHttpService } from '@core/services/number-verification-http.service';
import { OwlCarouselHttpService } from './core/services/owl-carousel-http.service';
import { EventEmitterService } from './core/services/event-emitter.service';
import { BannerHttpService } from './core/services/banner-http.service';
import { BankHttpService } from './core/services/bank-http.service';
import { AnnouncementHttpService } from './core/services/announcement-http.service';
import { LiveChatIdHttpService } from './core/services/live-chat-id-http.service';
import { GameProviderHttpService } from './core/services/game-provider-http.service';
import { GameCategoryHttpService } from './core/services/game-category-http.service';
import { MemberBankHttpService } from './core/services/member-bank-http.service';
import { MerchantBankHttpService } from './core/services/merchant-bank-http.service';
import { DropdownHttpService } from './core/services/dropdown-http.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { EntityDataModule, EntityMetadataMap } from '@ngrx/data';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { ViewsModule } from './views/views.module';
import { environment } from 'environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { metaReducers, reducers } from './store/reducers';
import { AuthModule } from '@views/pages/auth/auth.module';
import { DatePipe } from '@angular/common';
import { IpHttpService } from '@core/services/ip-http.service';
import { WalletHttpService } from '@core/services/wallet-http.service';
import { ContactListHttpService } from '@core/services/contact-list-http.service';
import { DashboardService } from '@core/services/dashboard.service';
import { LivechatWidgetModule } from '@livechat/angular-widget';
import { ContentModule } from '@views/pages/content/content.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { SharedModule } from '@shared/shared.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DesktopGuard } from '@core/guards/desktop.guard';
import { PreLoaderComponent } from '@shared/pre-loader/pre-loader.component';


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http,  `//${window.location.host}/assets/i18n/`, '.json');
}

export const entityMetadata: EntityMetadataMap = {
  GameList: { },
  GameCategory: { },
  BankAccount: { },
  Transfer: { },
  PromotionContent: { },
  ContactList: { },
  History: { }
};

const pluralNames = { };

@NgModule({
  declarations: [
    AppComponent,
    PreLoaderComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    MatDialogModule,
    StoreModule.forRoot(reducers, {metaReducers}),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    EffectsModule.forRoot([]),
    EntityDataModule.forRoot(pluralNames),
    StoreRouterConnectingModule.forRoot({ stateKey: 'router'}),
    AuthModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      },
      defaultLanguage: 'en'
    }),
    SharedModule.forRoot(),
    ContentModule,
    CoreModule,
    ViewsModule,
    LivechatWidgetModule
  ],
  providers: [
    DashboardService,
    DropdownHttpService,
    MerchantBankHttpService,
    MemberBankHttpService,
    DatePipe,
    IpHttpService,
    WalletHttpService,
    ContactListHttpService,
    BankHttpService,
    AnnouncementHttpService,
    LiveChatIdHttpService,
    BannerHttpService,
    EventEmitterService,
    OwlCarouselHttpService,
    DesktopGuard,
    NumberVerificationHttpService,

    // TODO: Remove and use the services found at "/app/services/games" directory
    GameCategoryHttpService,
    GameProviderHttpService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
