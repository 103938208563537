import { BankHttpService } from './bank-http.service';
import { MemberBankHttpService } from './member-bank-http.service';
import { MerchantBankHttpService } from './merchant-bank-http.service';
import { Injectable } from '@angular/core';

@Injectable()
export class DropdownHttpService {

  constructor(
    private merchantBankHttpService: MerchantBankHttpService,
    private memberBankHttpService: MemberBankHttpService,
    private bankHttpService: BankHttpService
  ){}
  merchantBankAccountsDeposit = this.merchantBankHttpService.getMerchantBankDeposits();
  memberBanks = this.memberBankHttpService.getMemberBanks();
  banks = this.bankHttpService.getAllBanks();
  paymentMethods = this.bankHttpService.getPaymenyMethods();
  ezPayList = this.bankHttpService.getEzPayList();
  helpToPayList = this.bankHttpService.getHelpToPayList();

  // Note: No DB hanlder for now...
  uploadTypes = [
    null,
    'banners',
    'receipts',
    'promotions',
    'uploads',
    'upload/card/photo/front/',
    'upload/card/photo/holding'
  ];

  languageCode = [
    null,
    'EN',
    'ZH',
    'MS',
    'TH'
  ];

  countryCode = [
    null,
    'MY',
    'TH',
    'SG'
  ];
}
