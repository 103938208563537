import { EventEmitterService } from '@core/services/event-emitter.service';
import { BankWrapperModalComponent } from '@shared/bank-wrapper-modal/bank-wrapper-modal.component';
import { map, tap } from 'rxjs/operators';
import { loggedUser } from '@core/store/auth/auth.selectors';
import { AppState } from '@store/reducers';
import { Store, select } from '@ngrx/store';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { BankAccountEntityService } from './../services/bank-account-entity.service';
import { BankAccountDataService } from './../services/bank-account-data.service';
import { AuthData } from '@core/models/auth-data';
import { BankAccount } from '@core/models/bank-account.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-bank-modal',
  templateUrl: './bank-modal.component.html',
  styleUrls: ['./bank-modal.component.scss']
})
export class BankModalComponent implements OnInit {

  bankFieldVisible = false;
  form: FormGroup;
  bankAccounts$: Observable<BankAccount[]>;
  dataSub$ = new BehaviorSubject<any>(null);
  isSuccess = this.bankAccountDataService.isSuccess;
  dropDownbanks: any;
  userName = localStorage.getItem('profile');
  messages$ = this.bankAccountDataService.messages$;
  user$: Observable<AuthData>;
  private subscription = new Subscription();

  @Input()
  onDialog = false;

  @Input()
  existing_bank_name: any;

  constructor(
    public dialogRef: MatDialogRef<BankWrapperModalComponent>,
    private bankAccountDataService: BankAccountDataService,
    private bankAccountEntityService: BankAccountEntityService,
    private dropdownHttpService: DropdownHttpService,
    private store: Store<AppState>,
    public loadingBar: LoadingBarService,
    private eventEmitterService: EventEmitterService
  ) { }

  ngOnInit() {
    this.formInit();
    this.reload();
    this.user$ = this.store.pipe(select(loggedUser));
    this.dropdownHttpService.banks.pipe(
      map((res) => {
        return res.filter((item)=> {
          return !this.existing_bank_name.includes(item.name);
        })
      })
    ).subscribe((res) => this.dropDownbanks = res);
  }

  onGetBankAccount() {
    return this.bankAccounts$ = this.bankAccountEntityService.getAll().pipe(
      tap(res => {
        this.dataSub$.next(res);
      })
    );
  }

  onCloseDialog(event?: Event ) {
    if (this.onDialog){
       this.dialogRef.close();
       this.eventEmitterService.onReloadBankList();
    }else{
      this.onGetBankAccount().subscribe();
      this.formInit();
    }
  }

  onSave() {
    this.loadingBar.start();
    const data = {
      ...this.form.value
    };
    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
    this.bankAccountDataService.addBank(data).subscribe( () => {
      this.loadingBar.complete();
      this.isSuccess = this.bankAccountDataService.isSuccess;
    });
  }

  private reload() {
    this.onGetBankAccount().subscribe();
  }

  private formInit() {
    this.form = new FormGroup({
      bank_id: new FormControl(null, [Validators.required]),
      account_number: new FormControl(null, [Validators.required]),
      bank_name: new FormControl(null),
    });
  }

  toggleBankField(value: any) {
    if (value === '0'){
      this.bankFieldVisible = true;
      this.form.controls[`bank_name`].setValidators(Validators.required);
    } else {
      this.bankFieldVisible = false;
      this.form.controls[`bank_name`].setValidators(null);
    }
  }

}
