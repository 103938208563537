import { ApiResponse } from './../models/api-response.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ContactList } from '@core/models//contact-list.model';

@Injectable()
export class ContactListHttpService {

  constructor(private http: HttpClient) { }

  getContactList(): Observable<ContactList[]> {
    return this.http.get<ApiResponse>(`/contact`).pipe(
      map(res => res.data)
    );
  }

  getContactListByType(type: string): Observable<ContactList[]> {
    return this.http.get<ApiResponse>(`/contact?type=${type}`).pipe(
      map(res => res.data)
    );
  }

}
