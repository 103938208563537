import { ApiResponse } from './../models/api-response.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable()
export class NumberVerificationHttpService {
  messages$ = new Subject<any[]>();
  isSuccess: boolean;
  constructor(private http: HttpClient) { }

  requestOTP(){
    return this.http.get<ApiResponse>(`/otp/request`).pipe(
      map(res => {
        this.messages$.next(res.message);
        this.isSuccess = res.success;
      })
    );
  }
  
  verifyOTP(data: any){
    return this.http.post<ApiResponse>(`/otp/verify`, data).pipe(
      map(res => {
        this.messages$.next(res.message);
        this.isSuccess = res.success;
      })
    );
  }

}
