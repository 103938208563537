<div class="modal show sign-in" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-body">
            <div class="modal-content modal-bg">
                <div class="modal-header">
                    <h4 class="modal-title" id="myModalLabel"><img src="/assets/images/register.png" width="25"> {{ 'Common.Join' | translate }}</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseDialog()">×</button>
                </div>
                <form [formGroup]="form" (ngSubmit)="submit(this.form.value)">
                  <div class="modal-body">
                      <p>{{ 'Auth.Username' | translate }}</p>
                      <input type="text" class="form-control" formControlName="username" (input)="toLowerCaseInput('username', $event)" #focusInput/>
                      <p>{{ 'Auth.PhoneNumber' | translate }}</p>
                      <input type="text" class="form-control" formControlName="phone_number"/>
                      <p>{{ 'Auth.Password' | translate }}</p>
                      <div class="input-group mb-4">
                          <input [type]="passwordInput.type" class="form-control m-b-0" formControlName="password" (keyup)="onCheckPassword()" #passwordField/>
                          <div class="input-group-append">
                              <button type="button" (click)="onPasswordMask()" class="input-group-text password-mask fs-13">
                                  <i [class]="passwordInput.icon"></i>
                              </button>
                          </div>
                      </div>
                      <p class="d-flex justify-content-between">{{ 'Member.Profile.ConfirmPassword' | translate }} 
                          <span *ngIf="!passwordMatched" class="text-danger">{{ 'Auth.PasswordNotMatched' | translate }}</span>
                      </p>
                      <div class="input-group mb-4">
                        <input [type]="passwordInput.type" class="form-control m-b-0" formControlName="confirm_password" (keyup)="onCheckPassword()" #passwordField/>
                        <div class="input-group-append">
                            <button type="button" (click)="onPasswordMask()" class="input-group-text password-mask fs-13">
                                <i [class]="passwordInput.icon"></i>
                            </button>
                        </div>
                    </div>
                      <p (click)="onCheck()" class="tnc"><input type="checkbox" [checked]="checkBox" class="form-check-input" id="exampleCheck1" formControlName="confirm_terms" required>{{ 'Auth.Confirm' | translate }}</p>
                      <div class="text-center m-b-20 m-t-20">
                          <button type="submit" class="btn-style1">{{ 'Common.Join' | translate }}</button>
                      </div>
                      <p class="m-t-20 m-b-20">{{ 'Auth.GuestText3' | translate }} <a routerLink="/login" (click)="onCloseDialog()" class="btn-signup"> {{ 'Auth.LoginCaption' | translate }}</a></p>
                  </div>
                </form>
            </div>
        </div>
    </div>
</div>

<app-swal-alert [message]="messages$ | async" [success]="isSuccess" (confirmed)="onRedirectLogin()"></app-swal-alert>
