import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-base-inner',
  templateUrl: './base-inner.component.html',
  styleUrls: ['./base-inner.component.scss']
})
export class BaseInnerComponent implements OnInit {


  constructor() {}

  ngOnInit() { }

}
