import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { PortalPromotion } from '@core/models/portal-promotion.model';
import { ApiResponse } from '@core/models/api-response.model';
import { PortalTransfer } from '@core/models/portal-transfer.model';
import { GameProvider } from '@core/models/games/game-provider.model';
import { Wallet } from '@core/models/wallet.model';

@Injectable()
export class PortalTransferHttpService  {

  messages$ = new Subject<any[]>();
  isSuccess: boolean;

  constructor(private http: HttpClient) {}

  transfer(data: PortalTransfer){
    return this.http.post<ApiResponse>('/transfer', data);
  }

  transferBy(provider: number, amount: any, direction: number){
    const data = {
      transfer_from: direction === 1 ? 0 : provider,
      transfer_to: direction === 1 ? provider : 0,
      promotion_id: 0,
      amount: (amount).toString().replace(/[\,]/, ''),
    };
    return this.http.post<ApiResponse>('/transfer', data);
  }

  getProviders(): Observable<GameProvider[]> {
    return this.http.get<ApiResponse>(`/gameprovider/list`).pipe(
      map(res => res.data.rows)
    );
  }

  getWalletDetails(provider: number): Observable<Wallet> {
    return this.http.get<ApiResponse>(`/wallet/balance?game_provider_id=${provider}`).pipe(
      map(res => res.data)
    );
  }

  getProviderBalanceBy(code: string): Observable<number> {
    return this.http.get<ApiResponse>(`/gameprovider/balance?game_provider_code=${code}&check_maintenance=false`).pipe(
      map(res => res.data.row.balance)
    );
  }

  getPromotions(providerId: number): Observable<PortalPromotion[]> {
    return this.http.get<ApiResponse>(`/transfer/promotion?game_provider_id=${providerId}`).pipe(
      map(res => res.data)
    );
  }

  getPromotionsByCode(providerCode: string): Observable<PortalPromotion[]> {
    return this.http.get<ApiResponse>(`/transfer/promotion?game_provider_code=${providerCode}`).pipe(
      map(res => res.data)
    );
  }

}
