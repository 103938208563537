import { Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Input, Inject, ViewChildren, QueryList, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Currency } from '@core/models/currency.model';
import { Observable, Subscription, zip, of } from 'rxjs';
import { PortalPromotion } from '@core/models/portal-promotion.model';
import { PortalTransferHttpService } from '@core/services/portal-transfer-http.service';
import { Store, select } from '@ngrx/store';
import { AppState } from '@store/reducers';
import { loggedUser } from '@core/store/auth/auth.selectors';
import { map, tap, delay } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TransferWrapperModalComponent } from '@shared/transfer-wrapper-modal/transfer-wrapper-modal.component';
import { DashboardService } from '@core/services/dashboard.service';

@Component({
  selector: 'app-transfer-modal',
  templateUrl: './transfer-modal.component.html',
  styleUrls: ['./transfer-modal.component.scss']
})
export class TransferModalComponent implements OnInit, OnDestroy {
  @ViewChildren('focusInput') focusInput: QueryList<ElementRef>;
  form: FormGroup;
  currency: Currency;
  providersDropdown = [];
  providersList = []; // with Balance
  promotions$: Observable<PortalPromotion[]>;

  messages$ = this.transferService.messages$;
  isSuccess = false;
  isAllin = false;

  contentHash = '';


  @ViewChild('promotionRef')
  promotionRef: ElementRef;

  @Input()
  isPerProvider = false;
  
  noPromotion = true;
  submitted = false;
  transferToFromValidate = false;

  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { provider: any },
    public dialogRef: MatDialogRef<TransferWrapperModalComponent>,
    private dashboardService: DashboardService,
    private transferService: PortalTransferHttpService,
    private store: Store<AppState>,
    public loadingBar: LoadingBarService,
    private router: Router
  ) { }

  ngOnInit() {
    this.isSuccess = false;
    this.messages$.next(['-']);

    this.store.pipe(select(loggedUser), map(res => res.currency)).subscribe(res => this.currency = res);
    this.formInit();
    if (this.isPerProvider) {
      this.promotions$ = this.transferService.getPromotionsByCode(this.data.provider.game_provider_code);
      of(null).pipe(
          delay(200), tap(() => this.focusInput.first.nativeElement.focus()
      )).subscribe();
    } else {
      this.dropdownInit();
    }

    if (this.dashboardService.subsVar === undefined) {
      this.dashboardService.subsVar = this.dashboardService.reloadProviderEmmiter.subscribe(() => {
        this.dropdownInit();
      });
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }

  onChangeAmount(amount: number) {
    this.form.patchValue({ amount });
  }

  submit() {
    this.submitted = true;
    if(this.form.valid && this.transferToFromValidate){
      this.loadingBar.start();
      this.transfer().subscribe((res) => {
        this.isSuccess = res.success;
        this.messages$.next(res.message);
        this.loadingBar.complete();
        this.submitted = false;
      });
    }
    // Tell dialog to react on changes
    this.contentHash = Math.random().toString(36).substring(7);
  }


  onAllIn(provider: number) {
    this.loadingBar.start();
    const transfers = [];
    this.providersList.map(row => {
      if (row.balance > 0.00 && row.status === 1) {
        transfers.push(this.transferService.transferBy(row.id, 'all', 2));
      }
    });
    if (transfers.length > 0){
      zip(...transfers).subscribe(() => {
        this.onTransferType(true, provider);
        this.dropdownInit();
        this.transferService.getWalletDetails(0).subscribe(
          (wallet) => this.dashboardService.refreshMemberBalance(wallet.balance)
        );
      });
    }else{
      this.onTransferType(true, provider);
    }
  }

  onCloseDialog(event?: Event ) {
    this.dialogRef.close();
  }

  onTransferChange() {
    const transferTo = +this.form.value.transfer_to;
    if (transferTo === 0) {
      this.form.patchValue({ promotion_id: 0});
      this.noPromotion = true;
    } else {
      this.promotions$ = this.transferService.getPromotions(+this.form.value.transfer_to);
      this.form.patchValue({ promotion_id: 0});
      this.noPromotion = false;
    }
  }

  private transfer() {
    this.onTransferType();
    const data = (this.isAllin) ? {...this.form.value, amount: 'all' } : this.form.value;
    return this.transferService.transfer(data).pipe(
      tap(res => {
        this.isSuccess = res.success;
        this.messages$.next(res.message);
        this.loadingBar.complete();
        if (res.success) {
          this.dropdownInit();
          this.noPromotion = true;
          this.transferService.getWalletDetails(0).subscribe(
            (wallet) => this.dashboardService.refreshMemberBalance(wallet.balance)
          );
          this.isAllin = false; // reset
        }
      })
    );
  }

  private onTransferType(allIn?: boolean, allInProvider?: number) {
    if (allIn) {
      this.isAllin = true;
      this.form.patchValue({
        promotion_id: 0,
        transfer_from: 0,
        transfer_to: allInProvider
      });
      return this.transfer().subscribe();
    }
  }

  private dropdownInit() {
    this.providersList = []; // Reset
    this.subscriptions.push(
      this.transferService.getProviders().pipe(
        tap((providers) => {
          this.providersDropdown = providers;
          providers.map(provider => {
            this.providersList.push({
              id: provider.id,
              code: provider.code,
              image_path: provider.image_path,
              balance: provider.balance,
              status: provider.status
            });
          });
        }),
      ).subscribe());
  }

  private formInit() {
    if (this.isPerProvider) {
      this.transferToFromValidate = true;
      this.form = new FormGroup({
        amount: new FormControl(null),
        promotion_id: new FormControl(0),
        transfer_from: new FormControl(0),
        transfer_to: new FormControl(this.data.provider.game_provider_id),
      });
    } else {
      this.form = new FormGroup({
        transfer_from: new FormControl(0, [Validators.required]),
        transfer_to: new FormControl(0, [Validators.required]),
        amount: new FormControl(null, [Validators.required]),
        promotion_id: new FormControl(0)
      });
      this.form.valueChanges.subscribe(res => {
        if(res.transfer_from === res.transfer_to){
          this.transferToFromValidate = false;
        }else{
          this.transferToFromValidate = true;
        }
      })
    }
  }

  closeDialog(event?: Event){
    this.formInit();
    this.onCloseDialog();
  }
}
