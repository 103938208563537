<div class="sign-in" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
      <!-- <div class="modal-body"> -->
          <div class="modal-content modal-bg-signin m-t-70">
              <div class="modal-header-signin">
                  <h4 class="modal-title-signin"><img src="/assets/images/login.png" width="30" >{{ 'Auth.SubHeading.Login' | translate }}</h4>
              </div>
              <div class="modal-body-signin">
                  <form [formGroup]="form" (ngSubmit)="submit()">
                    <p>{{ 'Auth.Username' | translate }}</p>
                    <input id="focusInput" type="text" formControlName="username" class="form-control" (input)="toLowerCaseInput('username', $event)"/>
                    <p>{{ 'Auth.Password' | translate }}</p>
                    <div class="input-group">
                        <input [type]="passwordInput.type" class="form-control m-b-0" formControlName="password" #passwordField/>
                        <div class="input-group-append">
                            <button type="button" (click)="onPasswordMask()" class="input-group-text password-mask fs-13">
                                <i [class]="passwordInput.icon"></i>
                            </button>
                        </div>
                    </div>
                    <div class="text-right fp-text">
                        <a routerLink="/forgot-password" class="btn-forgotpassword text-right"> {{ 'Auth.ForgotCaption' | translate }}?</a>
                    </div>
                    <div class="text-center m-b-20 m-t-30">
                        <input type="submit" class="btn-style1 text-uppercase " value="{{'Auth.LoginButton' | translate}}">
                    </div>
                    <p class="m-t-20 m-b-20">{{ 'Auth.GuestText1' | translate }} <a routerLink="/signup" class="btn-signup"> {{ 'Auth.SignUpCaption2' | translate }}</a></p>
                  </form>
              </div>
          </div>
      <!-- </div> -->
  </div>
</div>

<app-swal-alert [message]="messages$ | async" [success]="isSuccess"></app-swal-alert>
