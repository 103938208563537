import { ApiResponse } from '../models/api-response.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Dropdown } from '@core/models/dropdown.model';

@Injectable()
export class BankHttpService {

  constructor(private http: HttpClient) { }

  getAllBanks(): Observable<Dropdown[]>{
    return this.http.get<ApiResponse>(`/bankaccount/list`).pipe(
      map(res => res.data)
    );
  }

  getPaymenyMethods(): Observable<Dropdown[]>{
    return this.http.get<ApiResponse>(`/paymentmethod?type=1&status=1`).pipe(
      map(res => res.data.rows)
    );
  }

  getEzPayList(): Observable<Dropdown[]>{
    return this.http.get<ApiResponse>(`/banks/eeziepay`).pipe(
      map(res => res.data)
    );
  }

  getHelpToPayList(): Observable<Dropdown[]>{
    return this.http.get<ApiResponse>(`/banks/help2pay`).pipe(
      map(res => res.data)
    );
  }

}
