import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pre-loader',
  templateUrl: './pre-loader.component.html',
  styleUrls: ['./pre-loader.component.scss']
})
export class PreLoaderComponent implements OnInit {

  icons = [
    '/assets/images/preloading-icon/casino.svg',
    '/assets/images/preloading-icon/clubs.svg',
    '/assets/images/preloading-icon/diamond.svg',
    '/assets/images/preloading-icon/hearts.svg',
    '/assets/images/preloading-icon/spade.svg'
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
