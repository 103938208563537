import { DOCUMENT } from '@angular/common';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { Component, OnInit, DoCheck, AfterViewInit, OnDestroy, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoginModalComponent } from '@shared/login-modal/login-modal.component';
import { SignupModalComponent } from '@shared/signup-modal/signup-modal.component';
import { Store, select } from '@ngrx/store';
import { isLoggedIn } from '@core/store/auth/auth.selectors';
import { AuthActions } from '@core/store/auth/auth-action.types';
import { Observable, Subscription, timer } from 'rxjs';
import { AppState } from 'app/store/reducers';
import { WalletModalComponent } from '@shared/wallet-modal/wallet-modal.component';
import * as moment from 'moment';
import { LanguageModalComponent } from '@shared/language-modal/language-modal.component';
import { IpHttpService } from '@core/services/ip-http.service';
import { PortalMessageHttpService } from '@core/services/portal-message-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import {
  Router,
  RouteConfigLoadStart,
  RouteConfigLoadEnd,
  NavigationEnd,
  NavigationCancel,
  NavigationStart
} from '@angular/router';
import { DashboardService } from '@core/services/dashboard.service';
import { tap, switchMap } from 'rxjs/operators';
import { PortalMemberProfileHttpService } from '@core/services/portal-member-profile-http.service';
import { PortalMemberProfile } from '@core/models/portal-member-profile.model';
import { AuthHttpService } from '@core/services/auth-http.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit, AfterViewInit, DoCheck, AfterViewInit, OnDestroy {

  isLoggedIn$: Observable<boolean>;
  loggedUser$: Observable<PortalMemberProfile>;
  memberUsername$ = this.dashboardService.memberUsername$;

  dateTime: string;
  unreadMessages$: Observable<number>;
  hiddenSidePanel = true;

  private unsubscribe: Subscription[] = [];

  constructor(
    public dialog: MatDialog,
    private store: Store<AppState>,
    private ipService: IpHttpService,
    private portalMessageHttpService: PortalMessageHttpService,
    private portalMemberProfile: PortalMemberProfileHttpService,
    private dashboardService: DashboardService,
    private router: Router,
    public loadingBar: LoadingBarService,
    private eventEmitterService: EventEmitterService,
    private authService: AuthHttpService,
    @Inject(DOCUMENT) private document: Document
  ) {

    // Percentage just in case needed!
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.loadingBar.start();
      }
      if (event instanceof RouteConfigLoadStart) {
        this.loadingBar.increment(35);
      }
      if (event instanceof RouteConfigLoadEnd) {
        this.loadingBar.increment(75);
      }
      if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        this.loadingBar.complete();
      }
    });
  }

  ngOnInit() {
    this.isLoggedIn$ = this.store.pipe(select(isLoggedIn));

    if(!this.authService.isTokenExpired()) {
      this.loggedUser$ = this.portalMemberProfile.getProfile().pipe(
        tap(user => this.dashboardService.refreshMemberName(user.username))
      );
    }

    setInterval(() => {
      this.dateTime = moment(new Date()).local(true).format('YYYY-MM-DD HH:mm:ss');
    }, 1);


    if (this.eventEmitterService.subsVar === undefined) {
      this.eventEmitterService.subsVar = this.eventEmitterService.reloadMessageEmitter.subscribe((name: any) => {
        this.ngAfterViewInit();
      });
    }

    if (this.eventEmitterService.removeBodyClassVar === undefined) {
      this.eventEmitterService.removeBodyClassVar = this.eventEmitterService.removeBodyClassEmitter.subscribe((name: any) => {
        this.onHideSideBar();
      });
    }

  }

  ngAfterViewInit() {
    this.unreadMessages$ = timer(0, 58 * 1000).pipe(
      switchMap(() => this.getUnreadMessage())
    );
  }

  ngDoCheck() {
  }

  ngOnDestroy() {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
  }

  onHideSideBar(){
    this.hiddenSidePanel = !this.hiddenSidePanel;
    if (!this.hiddenSidePanel){
      this.document.body.classList.add('no-scroll');
    }else{
      this.document.body.classList.remove('no-scroll');
    }
  }

  onOpenDialog(type: string) {

    switch (type) {
      case 'login':
        this.openDialogBy(LoginModalComponent, 'login');
        break;

      case 'signup':
        this.openDialogBy(SignupModalComponent, 'signup');
        break;

      case 'wallet':
        this.openDialogBy(WalletModalComponent, 'wallet');
        break;

      case 'language':
        this.openDialogBy(LanguageModalComponent, 'language');
        break;
    }

  }

  onLogout() {
    this.store.dispatch(AuthActions.logout());
  }

  private getUnreadMessage(){
    if(!this.authService.isTokenExpired()) {
      return this.portalMessageHttpService.getUnread();
    }
  }

  private openDialogBy(componentRef: any, type: string) {
    if (type !== 'language'){
      this.dialog.open(componentRef, {
        width: '800px',
      });
    } else {
      this.dialog.open(componentRef);
    }
  }

  private userBrowserInit() {
    return this.ipService.getBrowserInfo();
  }

}
