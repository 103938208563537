<div *ngIf="data.type === 'game'">
  <div class="modal show" (click)="onCloseDialog()">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-body">
        <div class="modal-content modal-bg-promotion" (click)="$event.stopPropagation();">
          <div class="modal-header promotion-modal-header">
            <!-- <ng-container *ngIf="data.header">
            <img [src]="data.header">
          </ng-container> -->
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true"
              style="position: absolute; right: 10px" (click)="onCloseDialog()">×</button>
          </div>

          <div class="modal-header slots-modal-header">
            <img [src]="data.header" alt="Game Image">
          </div>
          <div class="gradient-fade"></div>

          <div class="modal-body promotion-modal-body promotion-table pb-4">
            <div class="promotion-info slots-info mb-0" style="color: #ffffff;">
              <div [innerHTML]="data.content"></div>

              <div class="text-center action-wrapper">
                <ng-container *ngIf=" data.categoryCode === 'LC'">
                  <a class="btn-style1"
                    (click)="onPlayGame(data.categoryCode, data.item, active); (isPlayTechClicked = (data.item.game_provider_code === 'PT') ? true : false)"
                    *ngIf="data.item.game_provider_code !== 'none'">{{ data.buttonText }}</a>
                </ng-container>
                <ng-container *ngIf=" data.categoryCode === 'SP'">
                  <a class="btn-style1"
                    (click)="onPlayGame(data.categoryCode, data.item, active); (isPlayTechClicked = (data.item.game_provider_code === 'PT') ? true : false)"
                    *ngIf="data.item.game_provider_code !== 'none'">{{ data.buttonText }}</a>
                </ng-container>

                <ng-container *ngIf=" data.categoryCode === 'SL'">
                  <a class="btn-style1" (click)="onSlotsPage(i)"
                    *ngIf="data.item.game_provider_code !== 'none'">{{ data.buttonText }}</a>
                </ng-container>

                <ng-container *ngIf=" data.categoryCode === 'FS'">
                  <a class="btn-style1" (click)="onFishingPage()"
                    *ngIf="data.item.game_provider_code !== 'none'">{{ data.buttonText }}</a>
                </ng-container>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div *ngIf="data.type === 'promotion'">
  <div class="modal show promotion-modal slideUp-content" (click)="onCloseDialog()">
    <div class="modal-content modal-bg-promotion" (click)="$event.stopPropagation();">
      <div class="modal-header promotion-modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true"
          style="position: absolute; right: 10px" (click)="onCloseDialog()">×</button>
      </div>
      <div class="modal-header promotion-modal-header">
        <img [src]="data.image" alt="Game Image">
      </div>
      <div class="gradient-fade"></div>
      <div class="promotion-modal-body ">
        <div class="title-border font-weight-bold mb-2 pb-2">
          <p class="modal-title-header">{{ data.header}}</p>
        </div>
      </div>
      <div class="modal-body promotion-modal-body promotion-table pb-4">
        <div class="promotion-info slots-info mb-0" style="color: #ffffff;">
          <div [innerHTML]="data.content"></div>
        </div>
      </div>
      <div class="text-center action-wrapper mb-4">
        <a class="btn-style1" (click)="onCloseDialog()">{{ data.buttonText }}</a>
      </div>

    </div>
  </div>
</div>

<app-logged-validator [isLoggedIn]="memberUser$ | async"></app-logged-validator>