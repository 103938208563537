import { BannerHttpService } from '@core/services/banner-http.service';
import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { isLoggedIn } from '@core/store/auth/auth.selectors';
import { Observable } from 'rxjs';
import { AppState } from '@store/reducers';
import { AnnouncementHttpService } from '@core/services/announcement-http.service';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {

  config: SwiperOptions = {
    pagination: { el: '.swiper-pagination', clickable: true },
    allowTouchMove: true,
    autoplay: {
      delay: 6000,
      disableOnInteraction: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    loop: true
  };

  images = [];
  
  visible = false;
  banners = ['sports-banner', 'fishing-banner', 'live-casino-banner', 'slots-banner'];
  bannerCount = this.banners.length;
  bannerPosition = 0;
  banners$ = this.bannerHttpService.getAllBanner();
  isLoggedIn$: Observable<boolean>;
  announcements$ = this.announcementHttpService.getAnnouncements();
  constructor(
    private store: Store<AppState>,
    private bannerHttpService: BannerHttpService,
    private announcementHttpService: AnnouncementHttpService,
  ) { 
    this.banners$.subscribe(banners => this.images = banners);
  }

  ngOnInit() {
    this.isLoggedIn$ = this.store.pipe(select(isLoggedIn));
  }

  getOffset(el: any) {
    el = el.getBoundingClientRect();
    return {
       left: el.left + window.scrollX,
       top: el.top + window.scrollY,
       bottom: el.top + window.scrollY
    };
  }

  scroll() {
    const scrollTo = document.getElementById('content');
    const topHight = this.getOffset(scrollTo).top;
    window.scrollTo(0, topHight);
  }
}
