import { Component, OnInit} from '@angular/core';
import { Location } from '@angular/common';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { TranslateService } from '@ngx-translate/core';
import { isLoggedIn } from '@core/store/auth/auth.selectors';
import { Store, select } from '@ngrx/store';
import { AppState } from '@store/reducers';

@Component({
  selector: 'app-language-modal',
  templateUrl: './language-modal.component.html',
  styleUrls: ['./language-modal.component.scss']
})
export class LanguageModalComponent implements OnInit {

  local = localStorage;
  languages = this.dropdownHttpService.languageCode;
  countries = this.dropdownHttpService.countryCode;

  constructor(
    private dropdownHttpService: DropdownHttpService,
    private location: Location,
    private translateService: TranslateService,
    private store: Store<AppState>,
  ) { }

  ngOnInit() { }

  setLanguage(language: string) {
    if (language === this.languages[4]) {
      localStorage.setItem('country_code', this.countries[2]);
    } else {
      localStorage.setItem('country_code', this.countries[1]);
    }
    localStorage.setItem('language_code', language);
    this.store.pipe(select(isLoggedIn)).subscribe(res => {
      if(!res){
        localStorage.setItem('isLanguageChanged', '1');
      }
    });

    // AI_CountryCode = localStorage.getItem('country_code');
    // AI_LangCode = localStorage.getItem('language_code');

    this.translateService.use(language.toLowerCase());
    this.translateService.setDefaultLang(language.toLowerCase());
    this.location.back();
  }
}
