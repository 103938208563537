import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { isLoggedIn } from '@core/store/auth/auth.selectors';
import { AppState } from '@store/reducers';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-bottombar',
  templateUrl: './bottombar.component.html',
  styleUrls: ['./bottombar.component.scss']
})
export class BottombarComponent implements OnInit, OnDestroy {

  subscription: Subscription;
  constructor(
    private store: Store<AppState>,
  ) { }

  ngOnInit() {
    this.subscription = this.store.pipe(select(isLoggedIn)).subscribe(res => {
      if (!res) {
        const script = document.createElement('script');
        script.src = '/assets/integrations/livechat-bar.js';
        document.getElementsByTagName('head')[0].appendChild(script);
      } else {
        document.body.classList.add('logged-in');
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
