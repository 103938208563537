<h2 class="pg-title">{{ 'Region.Title' | translate }}</h2>
<div class="row languages-country">
  <div class="col-3 col-md-3 center">
    <img src="/assets/images/language-icon/malaysia.png" width="40px" />
  </div>
  <div class="col-9 col-md-9">
    <h6>{{ 'Country.Malaysia' | translate }}</h6>
    <a (click)="setLanguage(languages[1])" [ngClass]="local.getItem('language_code') === languages[1] || local.getItem('language_code') === null? 'selected' : ''">{{ 'Language.English' | translate }}</a>
     <!-- / <a (click)="setLanguage(languages[2])" [ngClass]="local.getItem('language_code') === languages[2] ? 'selected' : ''">{{ 'Language.Chinese' | translate }}</a> -->
     <!-- | <a (click)="setLanguage(languages[3])" [ngClass]="session.getItem('language_code') === languages[3] || session.getItem('language_code') === null? 'selected' : ''">{{ 'Language.Malaysian' | translate }}</a> -->
  </div>
</div>

