<div class="content-wrap nommargin nopadding">

  <div class="col_full p-3">

    <h2 class="pg-title m-t-70">{{ 'Page.TermsConditions.Title' | translate }}</h2>

    <!-- Section 1 - General -->
    <h3 class="pg-subtitle">{{ 'Page.TermsConditions.Content.Section1.Heading' | translate }}</h3>
    <p class="formtext">{{ 'Page.TermsConditions.Content.Section1.Overview' | translate }}
      <br><br>{{ 'Page.TermsConditions.Content.Section1.P1' | translate }}
      <br><br>{{ 'Page.TermsConditions.Content.Section1.P2' | translate }}
      <br><br>{{ 'Page.TermsConditions.Content.Section1.P3' | translate }}
      <br><br>{{ 'Page.TermsConditions.Content.Section1.P4' | translate }}
      <br><br>{{ 'Page.TermsConditions.Content.Section1.P5' | translate }}
      <br><br>{{ 'Page.TermsConditions.Content.Section1.P6' | translate }}
      <br><br>{{ 'Page.TermsConditions.Content.Section1.P7' | translate }}
      <br><br>{{ 'Page.TermsConditions.Content.Section1.P8' | translate }}
      <br><br>{{ 'Page.TermsConditions.Content.Section1.P9' | translate }}
      <br><br>{{ 'Page.TermsConditions.Content.Section1.P10' | translate }}
      <br><br>{{ 'Page.TermsConditions.Content.Section1.P11' | translate }}
      <br><br>{{ 'Page.TermsConditions.Content.Section1.P12' | translate }}
      <br><br>{{ 'Page.TermsConditions.Content.Section1.P13' | translate }}
      <br><br>{{ 'Page.TermsConditions.Content.Section1.P14' | translate }}
      <br><br>{{ 'Page.TermsConditions.Content.Section1.P15' | translate }}
      <br><br>{{ 'Page.TermsConditions.Content.Section1.P16' | translate }}
    </p>

    <!-- Section 2 - General Promotion Terms & Conditions -->
    <h3 class="pg-subtitle">{{ 'Page.TermsConditions.Content.Section2.Heading' | translate }}</h3>
    <p class="formtext">{{ 'Page.TermsConditions.Content.Section2.P1' | translate }}
      <br><br>{{ 'Page.TermsConditions.Content.Section2.P2' | translate }}
      <br><br>{{ 'Page.TermsConditions.Content.Section2.P3' | translate }}
      <br><br>{{ 'Page.TermsConditions.Content.Section2.P4' | translate }}
      <br><br>{{ 'Page.TermsConditions.Content.Section2.P5' | translate }}
      <br><br>{{ 'Page.TermsConditions.Content.Section2.P6' | translate }}
      <br><br>{{ 'Page.TermsConditions.Content.Section2.P7' | translate }}
      <br><br>{{ 'Page.TermsConditions.Content.Section2.P8' | translate }}
      <br><br>{{ 'Page.TermsConditions.Content.Section2.P9' | translate }}
    </p>

  </div>
</div>