import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-game-menu',
  templateUrl: './game-menu.component.html',
  styleUrls: ['./game-menu.component.scss']
})
export class GameMenuComponent implements OnInit {

  @Input()
  fixed: string;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  onGamePage(gameCategory: string) {
    this.router.navigate(['/games'], { state: { activeGameCategoryTab: gameCategory } } );
  }

}
