import { EventEmitterService } from '@core/services/event-emitter.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Component, OnInit, AfterViewInit, QueryList, ElementRef, ViewChildren } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription, Observable, of } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AuthHttpService } from '@core/services/auth-http.service';
import { AppState } from 'app/store/reducers';
import { tap, delay } from 'rxjs/operators';
import * as fromAuthActions from '@core/store/auth/auth.actions';
import { isLoggedIn } from '@core/store/auth/auth.selectors';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {
  @ViewChildren('passwordField') passwordField: QueryList<ElementRef>;
  isLoggedIn$: Observable<boolean>;
  messages$ = this.auth.messages$;
  isSuccess = this.auth.isSuccess;
  form: FormGroup;
  passwordInput = {
    isMasked: true,
    type: null,
    icon: null,
    isValidPassword: true
  };

  private unsubscribe: Subscription[] = [];

  constructor(
    private router: Router,
    private auth: AuthHttpService,
    private store: Store<AppState>,
    private fb: FormBuilder,
    public loadingBar: LoadingBarService,
    private eventEmitterService: EventEmitterService
  ) { }

  ngOnInit() {
    this.onPasswordMask();
    this.store.pipe(select(isLoggedIn)).subscribe(res => {
      if (res) {
        this.router.navigate(['/']);
      }
    });
    this.initializeForm();
    document.getElementById('focusInput').focus();
    this.eventEmitterService.selectInputEmitter.subscribe((name: any) => {
        this.onSelectAll();
    });
  }

  ngAfterViewInit() { }

  submit() {
    sessionStorage.setItem('initialLogin', '1');
    this.loadingBar.start();
    const { username, password } = this.form.value;

    this.auth.login(username, password).pipe(
      tap( (res) => {
        this.store.dispatch(fromAuthActions.login( {
          user: res.data.user,
          token: res.data.token,
        }));
        localStorage.setItem('verified_number', res.data.user.phone_validated);
        if (res.data.user.name === '' && res.data.user.email === ''){
          this.router.navigate(['/member/profile']);
        } else {
          this.router.navigate(['/member/']);
          localStorage.setItem('profile', res.data.user.name);
        }
        this.loadingBar.complete();
      })
    ).subscribe(() =>  this.isSuccess = this.auth.isSuccess);
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.auth.forceLowerCaseInputControl(this.form, controlName, event);
  }

  onPasswordMask() {
    this.passwordInput.isMasked = !this.passwordInput.isMasked;
    this.passwordInput.type = this.passwordInput.isMasked ? 'text' : 'password';
    this.passwordInput.icon = this.passwordInput.isMasked ? 'icon-eye' : 'icon-eye-slash';
  }

  private onSelectAll(){
    this.passwordField.first.nativeElement.select();
  }

  private initializeForm() {
    this.form = this.fb.group({
      username: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(320)
        ])
      ],
      password: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(100)
        ])
      ]
    });
  }

}
