<app-bottombar id="footer-menu">
  <ng-container *ngIf="!(isLoggedIn$ | async) && !isAuthStandAlone">
    <div class="row">
        <div class="col-6 signup">
            <a (click)="onOpenDialog('signup')">
                <h4>{{ 'Common.Join' | translate }}</h4>
            </a>
        </div>
        <div class="col-6 signin">
            <a (click)="onOpenDialog('login')">
                <h4>{{ 'Auth.LoginButton' | translate }}</h4>
            </a>
        </div>
    </div>
  </ng-container>
  <ng-container *ngIf="(isLoggedIn$ | async)">
    <ul class="bottom-menu">
      <li>
        <a routerLink="/" class="bottomicnhome" routerLinkActive="bottomicnhome-active">
          <h6>{{ 'Page.Home.Title' | translate }}</h6>
        </a>
      </li>

      <li>
        <a routerLink="/member/profile" class="bottomicnaccount" routerLinkActive="bottomicnaccount-active">
          <h6>{{ 'Common.Account' | translate }}</h6>
        </a>
      </li>
      <li>
        <a (click)="onOpenDialog('wallet')" class="bottomicnwallet">
          <h6>{{ 'Common.Wallet' | translate }}</h6>
        </a>
      </li>
      <li>
        <a routerLink="/promotion" class="bottomicnpromo" routerLinkActive="bottomicnpromo-active">
          <h6>{{ 'Section.Promotion' | translate }}</h6>
        </a>
      </li>
      <li>
        <!-- <a (click)="openLiveChat()" class="bottomicnlivechat"> -->
        <a routerLink="/livechat" class="bottomicnlivechat" routerLinkActive="bottomicnlivechat-active">
          <h6>{{ 'Page.LiveChat.Title' | translate }}</h6>
        </a>
      </li>
    </ul>
  </ng-container>
</app-bottombar>

