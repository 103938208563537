<!-- <div class="loading top-most"> -->
  <!-- FOR FUTURE REFERENCE <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div> -->
  <!-- <div class="boxes">
      <div class="box">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
      </div>
      <div class="box">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
      </div>
      <div class="box">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
      </div>
      <div class="box">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
      </div>
  </div>
</div> -->


<div class="no-freeze-spinner">
    <div id="no-freeze-spinner">
        <div>
            <i class="material-icons" *ngFor="let item of icons">
                <img [src]="item">
            </i>
            <div>
            </div>
        </div>
    </div>
</div>