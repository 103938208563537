<div class="side-panel-wrap" (click)="onHideSideBar()">
    <ng-container *ngIf="(isLoggedIn$ | async)">
      <ng-container *ngIf="(userProfile | async) as user">
        <div class="side-panel-profile d-flex align-items-end">
            <div class="nav-tree mb-0">
                <ul class="al-i">
                    <li>
                        <a routerLink="/member/profile" class="sidebarmenu bl-0">
                            <img class="mb-3" [src]="user.member_group.image">
                            <span class="side-profile-text">Welcome, <br> <b>{{ user.username ? user.username : userData.username }}</b></span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
      </ng-container>
    </ng-container>
    <div class="widget clearfix">
        <nav class="nav-tree nobottommargin">
            <ul>
                <li routerLinkActive="active"><a routerLink="/" class="sidebarmenu" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><img src="/assets/images/side-menu-icons/home-hover.png"><span> {{ 'Page.Home.Title' | translate }}</span></a></li>
                <!-- <ng-container *ngIf="(isLoggedIn$ | async)">
                    <li routerLinkActive="active"><a routerLink="/member/profile" class="sidebarmenu" routerLinkActive="active"><img src="/assets/images/side-menu-icons/account-hover.png"><span>{{ 'Common.Account' | translate }}</span></a></li>
                </ng-container> -->
                <li routerLinkActive="active"><a routerLink="/promotion" class="sidebarmenu" routerLinkActive="active"><img src="/assets/images/side-menu-icons/promo-hover.png"><span>{{ 'Section.Promotion' | translate }}</span></a></li>
                <li routerLinkActive="active"><a routerLink="/vip" class="sidebarmenu" routerLinkActive="active"><img src="/assets/images/side-menu-icons/vip-hover.png"><span>{{ 'Section.VIP' | translate }}</span></a></li>
                <!-- <li routerLinkActive="active"><a routerLink="/vip" class="menuicnvip" routerLinkActive="active"><span>{{ 'Section.VIP' | translate }}</span></a></li> -->
                <li routerLinkActive="active"><a routerLink="/contact-us" class="sidebarmenu" routerLinkActive="active"><img src="/assets/images/side-menu-icons/contact-hover.png"><span>{{ 'Page.ContactUs.Title' | translate }}</span></a></li>
                <li routerLinkActive="active"><a routerLink="/language" class="sidebarmenu" routerLinkActive="active"><img src="/assets/images/side-menu-icons/language-hover.png"><span>{{ 'Common.Language' | translate }}</span></a></li>
                <!-- <li routerLinkActive="active"><a [href]="desktopDomain" (click)="onDesktop()" class="sidebarmenu"><img src="/assets/images/side-menu-icons/desktop-hover.png"><span>{{ 'Common.Desktop' | translate }}</span></a></li> -->
                <ng-container *ngIf="(isLoggedIn$ | async); else elseLoggedIn">
                    <li routerLinkActive="active"><a class="sidebarmenu" (click)="onLogout()" routerLinkActive="active"><img src="/assets/images/side-menu-icons/logout-hover.png"><span>{{ 'Auth.Logout' | translate }}</span></a></li>
                </ng-container>
                <ng-template #elseLoggedIn>
                    <li><a class="sidebarmenu" (click)="onOpenDialog('login')"><img src="/assets/images/side-menu-icons/login-hover.png"><span>{{ 'Auth.LoginButton' | translate }}</span></a></li>
                </ng-template>
            </ul>
        </nav>
    </div>
</div>
