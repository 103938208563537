<div class="forgot-password" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
      <div class="modal-body">
          <div class="modal-content modal-bg-forgot-password">
              <div class="modal-header-forgot-password m-t-70">
                  <h4 class="modal-title-forgot-password" id="myModalLabel"><img src="/assets/images/forgotpassword.png" width="25"> {{'Auth.ForgotCaption' | translate}}</h4>
              </div>
              <div class="modal-body-forgot-password">
                <form [formGroup]="form" (ngSubmit)="submit()">
                  <p>{{'Auth.Username' | translate}}</p>
                  <input id="focusInput" type="text" formControlName="username" class="form-control" (input)="toLowerCaseInput('username', $event)" />
                  <p>{{'Auth.PhoneNumber' | translate}}</p>
                  <input type="text" formControlName="phone_number" class="form-control" />
                  <div class="text-center m-b-20">
                      <input type="submit" class="btn-style1 text-uppercase" value="{{'Common.Submit' | translate}}">
                  </div>
                </form>
                <p class="m-t-20 m-b-20">{{'Auth.GuestText1' | translate}} <a routerLink="/signup" class="btn-signup"> {{'Auth.SignUpCaption' | translate}}</a></p>
              </div>
          </div>
      </div>
  </div>
</div>

<app-swal-alert [success]="isSuccess" [message]="messages$ | async" (confirmed)="onRedirectLogin()"></app-swal-alert>
