import { loggedUser } from '@core/store/auth/auth.selectors';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { Component, OnInit, ViewChild, DoCheck } from '@angular/core';
import { LiveChatWidgetModel } from '@livechat/angular-widget';
import { Observable } from 'rxjs';
import { AuthHttpService } from '@core/services/auth-http.service';
import { Store, select } from '@ngrx/store';
import { AppState } from '@store/reducers';
import { MatDialog } from '@angular/material/dialog';
import { LoginModalComponent } from '../../../shared/login-modal/login-modal.component';
import { isLoggedIn } from '@core/store/auth/auth.selectors';
import { AuthActions } from '@core/store/auth/auth-action.types';
import { environment } from '@env/environment';
import { AuthData } from '@core/models/auth-data';

@Component({
  selector: 'app-sidepanels',
  templateUrl: './sidepanels.component.html',
  styleUrls: ['./sidepanels.component.scss']
})
export class SidePanelsComponent implements OnInit, DoCheck {

  @ViewChild('liveChatWidget', {static: false}) public liveChatWidget: LiveChatWidgetModel;
  userProfile: Observable<AuthData>;
  public isLiveChatWidgetLoaded = false;
  language = localStorage.getItem('language_code') === 'MS' ? 11 : localStorage.getItem('language_code') === 'ZH' ? 10 : 9;

  isLoggedIn$: Observable<boolean>;
  userData = JSON.parse(localStorage.getItem('user_data'));

  mobileDomain: string;
  desktopDomain: string;

  constructor(
    public dialog: MatDialog,
    private authService: AuthHttpService,
    private store: Store<AppState>,
    private eventEmitterService: EventEmitterService
  ) { }

  ngOnInit() {
    this.mobileDomain = environment.mobileDomain;
    this.desktopDomain = environment.desktopDomain;
    this.isLoggedIn$ = this.store.pipe(select(isLoggedIn));
    this.userProfile = this.store.pipe(select(loggedUser));
  }

  ngDoCheck() {
  }

  onDesktop(){
    window.location.href = this.desktopDomain;
  }

  onHideSideBar(){
    this.eventEmitterService.onRemoveBodyClass();
  }

  onOpenChatBox(){
    this.liveChatWidget.openChatWindow();
  }

  openChatWindow(): void {
    if (this.isLiveChatWidgetLoaded) {
      this.liveChatWidget.openChatWindow();
    }
  }

  onLogout() {
    this.authService.logout().subscribe(() => this.store.dispatch(AuthActions.logout()));
  }

  onOpenDialog(type: string) {

    switch (type) {
      case 'login':
        this.openDialogBy(LoginModalComponent, 'login');
        break;

    }
  }

  private openDialogBy(componentRef: any, type: string) {
    if (type !== 'language'){
      this.dialog.open(componentRef, {
        width: '800px',
      });
    } else {
      this.dialog.open(componentRef);
    }
  }

}
