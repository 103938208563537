import { ApiResponse } from '@core/models/api-response.model';
import { BankAccount } from '@core/models/bank-account.model';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, tap, concatMap } from 'rxjs/operators';


@Injectable()
export class BankAccountDataService extends DefaultDataService<BankAccount> {

  messages$ = new Subject<any[]>();
  isSuccess: boolean;

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
      super('BankAccount', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<BankAccount[]>{
    return this.http.get<ApiResponse>(`/bankaccount`).pipe(
      map(res => res.data.rows)
    );
  }


  getWithQuery(pageParam: string): Observable<BankAccount[]>{
    return this.http.get<ApiResponse>(`/bankaccount${pageParam}`).pipe(
      map(res => res.data.rows)
    );
  }

  addBank(bankAccount: BankAccount){
    return this.http.post<ApiResponse>(`/bankaccount`, bankAccount).pipe(
      map( res => {
        this.messages$.next(res.message);
        this.isSuccess = res.success;
      })
    );
  }

  deleteBank(bankAccountId: number): Observable<BankAccount>{
    return this.http.delete<ApiResponse>(`/bankaccount/${bankAccountId}`).pipe(
      tap((res: any) => {
        this.messages$.next(res.message);
        this.isSuccess = res.success;
      })
    );
  }

}
