import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DeviceDetectorService } from 'ngx-device-detector';
import { delay, exhaust, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IpHttpService {

  constructor(
    private http: HttpClient,
    private deviceService: DeviceDetectorService
  ) { }

  getBrowserInfo(){
    return this.http.get('//myexternalip.com/json').pipe(
      delay(250),
      map((res: any) => {
        return {
          userIP: res.ip,
          isDesktop: ((this.deviceService.device).includes('iPad')) ? true : this.deviceService.isDesktop(),
          userAgent: this.deviceService.getDeviceInfo().userAgent
        };
      })
    );
  }

}
