import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, share, tap } from 'rxjs/operators';
import { GameList } from '../../models/games/game-list.model';
import { Game } from '../../models/games/game.model';
import { ApiResponse } from '../../models/api-response.model';
import Swal from 'sweetalert2';
import { GameSubCategory } from '@core/models/games/game-sub-category.model';
import { Pagination } from '@core/models/pagination.model';


@Injectable()
export class GameListDataService extends DefaultDataService<GameList> {

  pagination: Pagination;

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
      super('GameList', http, httpUrlGenerator, { root: '' });
  }

  getGameBalanceBy(providerCode: string, gameCode?: string): Observable<number> {
    return this.http.get<ApiResponse>(`/gameprovider/balance?game_provider_code=${providerCode}`).pipe(
      map(res => res.data.row.balance),
    );
  }

  getGamesBy(providerCode: string, categoryCode: string, page?: number, subCategory?: string, gameName?: string, gameSortBy?: string): Observable<GameList[]> {
    if (providerCode && categoryCode) {
      let pageParam = `?status=1&game_provider_code=${providerCode}&category_code=${categoryCode}`;
      if (subCategory) {
        pageParam += `&sub_category_name=${subCategory}`;
      }
      if (gameName) {
        pageParam += `&game_name=${gameName}`;
      }
      if (gameSortBy) {
        pageParam += `&sort=${gameSortBy}`;
      }
      if (page){
        pageParam += `&page=${page}`;
      }
      return this.http.get<ApiResponse>(`/gameprovider/getallgamelist${pageParam}`).pipe(
        tap(res => this.paginationInit(res)),
        map(res => {
          if (res.data && res.data.rows !== null) {
            return res.data.rows;
          }
        })
      );
    }
  }

  getSubCategories(providerCode: string, categoryCode: string): Observable<GameSubCategory[]> {
    if (providerCode && categoryCode) {
      const pageParam = `?status=1&game_provider_code=${providerCode}&category_code=${categoryCode}`;
      return this.http.get<ApiResponse>(`/gameprovider/subcategory${pageParam}`).pipe(
        share(),
        map(res => res.data.rows)
      );
    }
  }

  launchGame(categoryCode: string, providerCode: string, gameCode: string, gameType?: string): Observable<Game> {
    const { isDesktop } = JSON.parse((localStorage.getItem('user_browser')));
    const deviceType = isDesktop ? 'desktop' : 'mobile';
    let suffix = '';
    if (gameCode) {
      suffix += `&game_code=${gameCode}`;
    }
    const pageParam = `?category_code=${categoryCode}&game_provider_code=${providerCode}&game_type=${gameType}&device_type=${deviceType}${suffix}`;
    return this.http.get<ApiResponse>(`/gameprovider/launch${pageParam}`).pipe(
      map(res => res.data.row)
    );
  }

  private paginationInit(res: any) {
    if (res) {
      if (res.data !== null && res.data.paginations) {
        this.pagination = res.data.paginations;
      }
    }
  }

}
