import { Injectable } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Injectable()
export class OwlCarouselHttpService {

  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    margin: 6,
    startPosition: 0,
    navText: ['<i class="icon-chevron-left"></i>', '<i class="icon-chevron-right"></i>'],
    responsive: {
      0: {
        items: 3.5
      },
      740: {
        items: 4.5
      }
    },
    // nav: true
  };

  constructor() { }

}
