<div class="container clearfix" *ngIf="(isLoggedIn$ | async)">

  <div class="row" id="wallet-bar">
    <div class="col-5 p-l-10 p-r-0" style="display: flex;">
      <ng-container *ngIf="!isTokenExpired">
        <div class="wallet-refresh">
          <img [ngClass]="{'refresh-spin': isClicked}" (click)="onRefreshBalance()" src="/assets/images/refresh.png" />
        </div>
        <div class="wallet-amount">
          <h6>
            {{ 'Member.Transfer.MainWallet' | translate }} ({{ memberCurrency }})<br />
            <span>{{ (memberBalance$ | async) | number : '1.2-2' }} &nbsp;</span>
          </h6>
        </div>
      </ng-container>
    </div>
    <div class="col-7 p-l-0 p-r-0">
      <ul class="wallet-navi">
        <li>
          <a routerLink="/member/deposit" class="btn-deposit">
            <img src="/assets/images/deposit.png" class="blue-deposit" />
            <img src="/assets/images/deposit-hover.png" class="white-deposit" style="display: none;" />
            <h6>{{ 'Common.Deposit' | translate }}</h6>
          </a>
        </li>

        <li>
          <a routerLink="/member/transfer" class="btn-transfer">
            <img src="/assets/images/transfer.png" class="blue-transfer" />
            <img src="/assets/images/transfer-hover.png" class="white-transfer" style="display: none;" />
            <h6>{{ 'Common.Transfer' | translate }}</h6>
          </a>
        </li>
        <li>
          <a routerLink="/member/withdraw" class="btn-withdraw">
            <img src="/assets/images/withdraw.png" class="blue-withdraw" />
            <img src="/assets/images/withdraw-hover.png" class="white-withdraw" style="display: none;" />
            <h6>{{ 'Common.Withdraw' | translate }}</h6>
          </a>
        </li>
        <li>
          <a routerLink="/member/history" class="btn-history">
            <img src="/assets/images/history.png" class="blue-history" />
            <img src="/assets/images/history-hover.png" class="white-history" style="display: none;" />
            <h6>{{ 'Member.History.Title' | translate }}</h6>
          </a>
        </li>
      </ul>
    </div>
  </div>


</div>
