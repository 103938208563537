import { tap, map } from 'rxjs/operators';
import { Pagination } from './../models/pagination.model';
import { PortalMessage } from './../models/portal-message.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class ForgotPasswordHttpService {

    messages$ = new Subject<any[]>();
    isSuccess: boolean;

    constructor(private http: HttpClient) { }

    forgotPassword(data: any){
      return this.http.post<ApiResponse>(`/forgotpassword`, data).pipe(
        tap( res => {
          this.messages$.next(res.message);
          this.isSuccess = res.success;
      }));
    }

}
