import { Component, OnInit } from '@angular/core';
import { ContactListHttpService } from '@core/services/contact-list-http.service';


@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  contactList$ = this.contactListHttpService.getContactList();

  constructor(
    private contactListHttpService: ContactListHttpService,
  ) { }

  ngOnInit(): void {
  }

}
