import { ApiResponse } from '../models/api-response.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Announcement } from '@core/models/announcement.model';

@Injectable()
export class AnnouncementHttpService {

  constructor(private http: HttpClient) { }

  getAnnouncements(): Observable<Announcement[]>{
    return this.http.get<ApiResponse>(`/announcement`).pipe(
      map(res => res.data.rows)
    );
  }

}
