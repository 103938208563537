<div class="modal show sign-in" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
      <div class="modal-body">
          <div class="modal-content modal-bg">
              <div class="modal-header">
                  <h4 class="modal-title" id="myModalLabel"><img src="/assets/images/login.png" width="25"> {{ 'Auth.SubHeading.Login' | translate }}</h4>
                  <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseDialog()">×</button>
              </div>
              <form [formGroup]="form" (ngSubmit)="submit()">
                <div class="modal-body">
                    <p>{{ 'Auth.Username' | translate }}</p>
                    <input type="text" class="form-control" formControlName="username" (input)="toLowerCaseInput('username', $event)" #focusInput/>
                    <p>{{ 'Auth.Password' | translate }}</p>
                    <div class="input-group">
                        <input [type]="passwordInput.type" class="form-control m-b-0" formControlName="password" #passwordField/>
                        <div class="input-group-append">
                            <button type="button" (click)="onPasswordMask()" class="input-group-text password-mask fs-13">
                                <i [class]="passwordInput.icon"></i>
                            </button>
                        </div>
                    </div>
                    <div class="text-right fp-text">
                        <a routerLink="/forgot-password" (click)="onCloseDialog()" class="btn-forgotpassword text-right"> {{ 'Auth.ForgotCaption' | translate }}?</a>
                    </div>
                    <div class="text-center m-b-20 m-t-20">
                        <button type="submit" class="btn-style1">{{ 'Auth.LoginButton' | translate }}</button>
                    </div>
                    <p class="m-t-20 m-b-20">{{ 'Auth.GuestText1' | translate }} <a routerLink="/signup" (click)="onCloseDialog()" class="btn-signup"> {{ 'Auth.SignUpCaption2' | translate }}</a></p>
                </div>
              </form>
          </div>
      </div>
  </div>
</div>

<app-swal-alert [message]="messages$ | async" [success]="isSuccess"></app-swal-alert>
