import { NgModule } from '@angular/core';

import { ContentRoutingModule } from './content-routing.module';
import { AboutComponent } from './subcomponents/about/about.component';
import { TermsConditionsComponent } from './subcomponents/terms-conditions/terms-conditions.component';
import { LiveChatComponent } from './subcomponents/live-chat/live-chat.component';
import { GuestComponent } from './content.component';
import { ContactUsComponent } from './subcomponents/contact-us/contact-us.component';
import { SharedModule } from '@shared/shared.module';
import { ViewsModule } from '@views/views.module';
import { LanguageComponent } from './subcomponents/language/language.component';

const components = [
  GuestComponent,
  AboutComponent,
  TermsConditionsComponent,
  LiveChatComponent,
  ContactUsComponent,
  LanguageComponent
];

@NgModule({
  declarations: [
    ...components
  ],
  exports: [
    ...components
  ],
  imports: [
    SharedModule,
    ViewsModule,
    ContentRoutingModule
  ]
})
export class ContentModule { }
