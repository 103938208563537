
<ng-container *ngIf="onDialog">
  <div class="modal show sign-in" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-body">
            <div class="modal-content modal-bg">
                <div class="modal-header">
                    <h4 class="modal-title" id="myModalLabel">{{ 'Common.BankAccount' | translate }} </h4>
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseDialog()">×</button>
                </div>
                <form [formGroup]="form" class="p-25">
                  <div class="user-input">
                    <label>{{ 'Common.Bank' | translate }} </label>
                    <select class="form-control required" formControlName="bank_id" (change)="toggleBankField($event.target.value)">
                      <option [value]="item.id" *ngFor="let item of dropDownbanks ">{{ item.name }}</option>
                      <option [value]="0">{{ 'Member.BankAccount.Others' | translate }}</option>
                    </select>

                    <label>{{ 'Common.BankAccount' | translate }} </label>
                    <input *ngIf="userName !== null" type="text" class="form-control m-b-10" [value]="userName" placeholder="Account Name" readonly/>
                    <input *ngIf="userName === null" type="text" class="form-control m-b-10" [value]="(user$ | async).name" placeholder="Account Name" readonly/>

                    <input type="text" class="form-control" placeholder="{{ 'Member.BankAccount.AccountNumber' | translate }}" formControlName="account_number" />

                    <div class="col-form-label" style="padding-left:0" *ngIf="bankFieldVisible === true">
                      <label>{{ 'Member.BankAccount.BankName' | translate }}: <span class="text-danger"></span></label>
                    </div>
                    <div class="form-group" style="padding-left:0" *ngIf="bankFieldVisible === true">
                      <div class="row">
                        <div class="col-md-12">
                          <input class="form-control" formControlName="bank_name" type="text" placeholder="Enter Your Bank Name">
                        </div>
                      </div>
                    </div>

                  </div>

                  <div class="m-t-30">
                    <a data-toggle="modal" class="btn-style1" data-target="#warning-msg" (click)="onSave()">{{ 'Common.Save' | translate }}</a>
                  </div>
                </form>
            </div>
        </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!onDialog">
  <form [formGroup]="form">
    <div class="title-page-blue m-b-5">
      <h3>{{ 'Common.BankAccount' | translate }}</h3>
    </div>
    <div class="user-input">
      <label>{{ 'Common.Bank' | translate }} </label>
      <select class="form-control required" formControlName="bank_id" (change)="toggleBankField($event.target.value)">
        <option [value]="item.id" *ngFor="let item of dropDownbanks | async">{{ item.name }}</option>
        <option [value]="0">{{ 'Member.BankAccount.Others' | translate }}</option>
      </select>

      <label>{{ 'Common.BankAccount' | translate }} </label>
      <input *ngIf="userName !== null" type="text" class="form-control m-b-10" [value]="userName" placeholder="Account Name" readonly/>
      <input *ngIf="userName === null" type="text" class="form-control m-b-10" [value]="(user$ | async).name" placeholder="Account Name" readonly/>

      <input type="text" class="form-control" placeholder="{{ 'Member.BankAccount.AccountNumber' | translate }}" formControlName="account_number" />

      <div class="col-form-label" style="padding-left:0" *ngIf="bankFieldVisible === true">
        <label>{{ 'Member.BankAccount.BankName' | translate }}: <span class="text-danger"></span></label>
      </div>
      <div class="form-group" style="padding-left:0" *ngIf="bankFieldVisible === true">
        <div class="row">
          <div class="col-md-12">
            <input class="form-control" formControlName="bank_name" type="text" placeholder="Enter Your Bank Name">
          </div>
        </div>
      </div>

    </div>

    <div class="m-t-30">
      <a data-toggle="modal" class="btn-style1" data-target="#warning-msg" (click)="onSave()">{{ 'Common.Save' | translate }}</a>
    </div>
  </form>
  <br />

  <div class="overflow-table m-b-30">
    <table class="member-area-table">
      <thead>
        <tr>
          <th>{{ 'Common.Bank' | translate }}</th>
          <th>{{ 'Member.BankAccount.AccountName' | translate }}</th>
          <th>{{ 'Member.BankAccount.AccountNumber' | translate }}</th>
        </tr>
      </thead>
      <tbody *ngIf="(bankAccounts$ | async) as rows">
        <tr *ngFor="let row of rows">
          <td>{{ row.bank_name }}</td>
          <td>{{ row.account_name }}</td>
          <td>{{ row.account_number }}</td>
        </tr>
      </tbody>
    </table>
  </div>

</ng-container>


<app-swal-alert [success]="isSuccess" [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></app-swal-alert>
