import { ApiResponse } from './../models/api-response.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MemberBalance } from './../models/member-balance.model';

@Injectable()
export class WalletHttpService {

  constructor(private http: HttpClient) { }

  getMemberBalance(): Observable<MemberBalance> {
    return this.http.get<ApiResponse>(`/wallet/balance?game_provider_id=0`).pipe(
      map(res => res.data)
    );
  }

}
