import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GameCategory } from '../../models/games/game-category.model';
import { ApiResponse } from '../../models/api-response.model';
import Swal from 'sweetalert2';


@Injectable()
export class GameCategoryDataService extends DefaultDataService<GameCategory> {

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
      super('GameCategory', http, httpUrlGenerator, { root: '' });
  }

  getWithQuery(pageParam: string): Observable<GameCategory[]>{
    return this.http.get<ApiResponse>(`/gameprovider/gamelistbycategory${pageParam}`).pipe(
      map(res => res.data.rows)
    );
  }

  getAllGameProvider(): Observable<any[]>{
    return this.http.get<ApiResponse>(`/gameprovider/gamelistbycategory`).pipe(
      map(res => res.data.rows)
    );
  }

}
