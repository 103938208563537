import {createAction, props} from '@ngrx/store';
import { Auth } from '@core/models/auth.model';
import { AuthRegister } from '@core/models/auth-register.model';
import { PortalMemberProfile } from '@core/models/portal-member-profile.model';
import { AuthData } from '@core/models/auth-data';

export const login = createAction('[Login Page] User Login', props<Auth>());
export const changeProfile = createAction('[Profile Page] Update Profile', props<{ profile: PortalMemberProfile | AuthData }>());
export const register = createAction('[Login Page] User Signup');
export const logout = createAction('[Top Menu] Logout');
