import { select } from '@ngrx/store';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription, zip, of } from 'rxjs';
import { Wallet } from '@core/models/wallet.model';
import { PortalTransferHttpService } from '@core/services/portal-transfer-http.service';
import { GameProvider } from '@core/models/games/game-provider.model';
import { tap } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { DashboardService } from '@core/services/dashboard.service';

@Component({
  selector: 'app-wallet-modal',
  templateUrl: './wallet-modal.component.html',
  styleUrls: ['./wallet-modal.component.scss']
})
export class WalletModalComponent implements OnInit, OnDestroy {

  wallet$: Observable<Wallet>;
  isDisabled = false;
  messages$ = this.transferService.messages$;
  isSuccess = this.transferService.isSuccess;

  walletBalance: number;
  showLoading = false;
  showPreloader = false;
  currentGameCode = null;
  providerStack: any[] = []; // with Balance
  currencyCode: string;
  count: number;
  providerBalance = new Subscription();

  private subscription: Subscription[] = [];

  constructor(
    private transferService: PortalTransferHttpService,
    public dialogRef: MatDialogRef<WalletModalComponent>,
    private dashboardService: DashboardService,
    public loadingBar: LoadingBarService,
  ) { }

  ngOnInit() {
    this.getProviders();
    //this.loadingBar.start();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.providerBalance.unsubscribe();
    this.subscription.map(sb => sb.unsubscribe());
  }

  onRestore() {
    this.isDisabled = true;
    const transfers = [];
    this.loadingBar.start();
    this.providerStack.map(row => {
      if (row.balance > 0.00 && row.status === 1) {
        transfers.push(this.transferService.transferBy(row.id, 'all', 2));
      }
    });

    if (transfers.length === 0) {
      this.isDisabled = false;
      this.loadingBar.complete();
      this.dashboardService.reloadProvider();
    }

    zip(...transfers).subscribe(() => {
      this.getProviders();
      this.dashboardService.reloadProvider();
    });
  }

  onAllIn(provider: number, code: string, ind: number) {
    this.loadingBar.start();
    const transfers = [];
    this.providerStack.map(row => {
      if (row.balance > 0.00 && row.status === 1) {
        transfers.push(this.transferService.transferBy(row.id, 'all', 2));
      }
    });
    if (transfers.length > 0) {
      zip(...transfers).subscribe(() => {
        this.allInAProvider(provider, code, ind);
      });
    } else {
      this.allInAProvider(provider, code, ind);
    }
  }

  onRefreshBalance() {
    return this.wallet$ = this.getWallet().pipe(
      tap((res) => {
        this.walletBalance = res.balance;
        this.dashboardService.refreshMemberBalance(this.walletBalance);
      })
    );
  }

  needAllin(amount: number) {
    if (amount <= 0) {
      return false;
    }
    return true;
  }

  private allInAProvider(provider: number, code: string, ind: number) {
    this.subscription.push(
      this.transferService.transferBy(provider, 'all', 1).subscribe(res => {
        this.isSuccess = res.success;
        this.messages$.next(res.message);
        if (res.success) {
          this.onRefreshBalance();
          // this.getProviderBalance(code, ind); FOR REFERENCE
          this.getProviders();
        }
        this.dashboardService.reloadProvider();
      })
    );
  }

  public getProviders() {
    this.providerStack = [];
    this.onRefreshBalance();
    this.transferService.getProviders()
      .pipe(
        tap((providers) => {
          providers.map(provider => {
            this.providerStack.push({
              name: provider.name,
              id: provider.id,
              code: provider.code,
              image_path: provider.image_path,
              balance: provider.balance,
              status: provider.status
            });
          });
          this.isDisabled = false;
          this.loadingBar.complete();
        }),
      ).subscribe();
  }

  private getWallet() {
    return this.wallet$ = this.transferService.getWalletDetails(0).pipe(
      tap(res => {
        this.currencyCode = res.currency_code;
        this.isDisabled = false;
        this.loadingBar.complete();
      })
    );
  }

  public refresh() {
    this.isDisabled = true;
    this.showPreloader = true;
    this.transferService.getWalletDetails(0).pipe(
      tap(res => {
        this.wallet$ = of(res);
        this.walletBalance = res.balance;
        this.currencyCode = res.currency_code;
      })
    ).subscribe(() => {
      this.showPreloader = false
    });
    
    this.showLoading = true;
    this.count = Object.keys(this.providerStack).length;
    Object.keys(this.providerStack).forEach((key) => {
      this.providerStack[key].status === 1 ? this.getProviderBalance(this.providerStack[key].code, parseInt(key)) : this.count--;
    }); 
  }
 
  private getProviderBalance(code: string, ind: number) {
    this.providerBalance = this.transferService.getProviderBalanceBy(code).pipe(
      tap(res => {
        this.currentGameCode = code;
        this.providerStack[ind] = {
          ...this.providerStack[ind],
          balance: res,
        };
      })
    ).subscribe(() => {
      this.count--;
      
      if ( this.count === 0) {
        this.showLoading = false;
        this.isDisabled = false;
        this.currentGameCode = null;
      }
    }
    )
  
  }
}
