import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-processing-transaction',
  templateUrl: './processing-transaction.component.html',
  styleUrls: ['./processing-transaction.component.scss']
})
export class ProcessingTransactionComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    const transactionForm = JSON.parse(localStorage.getItem('transactionForm'));
    const { params, url } = transactionForm;
    this.postPayment(params, url);
  }

  private postPayment(obj: any, url: string) {
    if(Object.keys(obj) && url !== null) {
      const mapForm = document.createElement('form');
      mapForm.method = 'POST';
      mapForm.action = url;
      Object.keys(obj).map( key => {
        const mapInput = document.createElement('input');
        mapInput.type = 'hidden';
        mapInput.name = key;
        mapInput.setAttribute('value', obj[key]);
        mapForm.appendChild(mapInput);
      });
      document.body.appendChild(mapForm);
      mapForm.submit();
      localStorage.removeItem('transactionForm');
    } else {
      this.router.navigateByUrl('/home');
    }
  }

}
