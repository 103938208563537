import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { PortalComponent } from '@views/pages/portal/portal.component';
import { SignupComponent } from '@views/pages/auth/subcomponents/signup/signup.component';
import { LoginComponent } from '@views/pages/auth/subcomponents/login/login.component';
import { ForgotPasswordComponent } from '@views/pages/auth/subcomponents/forgot-password/forgot-password.component';
import { SignupGuard } from '@core/guards/signup.guard';
import { AboutComponent } from '@views/pages/content/subcomponents/about/about.component';
import { TermsConditionsComponent } from '@views/pages/content/subcomponents/terms-conditions/terms-conditions.component';
import { LiveChatComponent } from '@views/pages/content/subcomponents/live-chat/live-chat.component';
import { ContactUsComponent } from '@views/pages/content/subcomponents/contact-us/contact-us.component';
import { LanguageComponent } from '@views/pages/content/subcomponents/language/language.component';
import { DesktopGuard } from '@core/guards/desktop.guard';
import { ProcessingTransactionComponent } from '@shared/processing-transaction/processing-transaction/processing-transaction.component';

const routes: Routes = [
  // {
  //   path: '',
  //   component: AuthComponent,
  //   pathMatch: 'full',
  //   loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule),
  //   data: {title: 'Auth'}
  // },
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./views/pages/home/home.module').then(m => m.HomeModule),
    data: {title: 'Home'},
    canActivate: [DesktopGuard]
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {title: 'Login'},
    canActivate: [DesktopGuard]
  },
  {
    path: 'signup',
    component: SignupComponent,
    data: {title: 'Sign Up'},
    canActivate: [SignupGuard, DesktopGuard],
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    data: {title: 'Forgot Password'},
    canActivate: [DesktopGuard]
  },
  {
    path: 'landing',
    loadChildren: () => import('./views/pages/landing/landing.module').then(m => m.LandingModule),
    data: {title: 'Landing'},
    canActivate: [DesktopGuard]
  },
  // {
  //   path: '',
  //   loadChildren: () => import('./views/pages/content/content.module').then(m => m.ContentModule),
  //   data: {title: 'Content'}
  // },
  {
    path: 'about-us',
    component: AboutComponent,
    data: {title: 'About-Us'},
    canActivate: [DesktopGuard]
  },
  {
    path: 'terms-conditions',
    component: TermsConditionsComponent,
    data: {title: 'Terms-Condictions'},
    canActivate: [DesktopGuard]
  },
  {
    path: 'live-chat',
    component: LiveChatComponent,
    data: {title: 'Live-Chat'},
    canActivate: [DesktopGuard]
  },
  {
    path: 'contact-us',
    component: ContactUsComponent,
    data: {title: 'Contact-Us'},
    canActivate: [DesktopGuard]
  },
  {
    path: 'language',
    component: LanguageComponent,
    data: {title: 'Region & Language'},
    canActivate: [DesktopGuard]
  },
  {
    path: 'member',
    canActivateChild: [AuthGuard],
    component: PortalComponent,
    canActivate: [DesktopGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./views/pages/portal/portal.module').then(m => m.PortalModule),
        data: {title: 'Member'},
      }
    ]
  },
  {
    path: 'games',
    loadChildren: () => import('./views/pages/games/games.module').then(m => m.GamesModule),
    data: {title: 'Games'},
    canActivate: [DesktopGuard]
  },
  {
    path: 'promotion',
    loadChildren: () => import('./views/pages/promotion/promotion.module').then(m => m.PromotionModule),
    data: {title: 'Promotion'},
    canActivate: [DesktopGuard]
  },
  {
    path: 'referral',
    loadChildren: () => import('./views/pages/referral/referral.module').then(m => m.ReferralModule),
    data: {title: 'Referral'},
    canActivate: [DesktopGuard]
  },
  {
    path: 'vip',
    loadChildren: () => import('./views/pages/vip/vip.module').then(m => m.VipModule),
    data: {title: 'VIP'},
    canActivate: [DesktopGuard]
  },
  {
    path: 'gamelaunch',
    loadChildren: () => import('./views/pages/gamelaunch/gamelaunch.module').then(m => m.GameLaunchModule),
    data: {title: 'Launch Game'},
    canActivate: [DesktopGuard]
  },
  {
    path: 'livechat',
    loadChildren: () => import('./views/pages/livechat/livechat.module').then(m => m.LiveChatModule),
    data: {title: 'LiveChat'},
    canActivate: [DesktopGuard]
  },
  /*
  {
    path: '', pathMatch: 'full', redirectTo: 'landing'
  },
  */
  {
    path: 'processing-transaction',
    component: ProcessingTransactionComponent,
    data: {
      title: 'Processing Transaction',
    }
  },
  {
    path: '**', redirectTo: '/', pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
