import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tinypreloader',
  templateUrl: './tinypreloader.component.html',
  styleUrls: ['./tinypreloader.component.scss']
})
export class TinypreloaderComponent implements OnInit {

  @Input()
  isSearching = false;

  constructor() { }

  ngOnInit(): void {
  }

}
