<div class="modal show all-in-wallet slideUp-content" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
			<div class="modal-content modal-bg-wallet">
				<div class="modal-header">
					<h4 class="modal-title" id="myModalLabel"><img src="/assets/images/bottom-icn-wallet.png" width="25"> {{ 'Common.Wallet' | translate }}</h4>
					<button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseDialog()">×</button>
				</div>
				<div class="modal-body pt-0">
          <ng-container *ngIf="(wallet$ | async) as wallet">
            <div class="wallet-balance row">
              <div class="col-2 ">
				<p style="font-size:0.65rem">{{ 'Common.Balance' | translate }}<br>({{ wallet.currency_code }})</p>
			  </div>
	
			  <div class="col-1 p-l-0">	
				<span *ngIf="showPreloader" class="spinner-border spinner-border-md" role="status" aria-hidden="true"></span>
			  </div>

              <div class="col-9 wallet-balance-amount ">
				<p> {{ wallet.balance | number : '1.2-2' }} &nbsp;</p>				 
              </div>
            </div>
          </ng-container>
					<div class="row mt-1">
						<div class="col-6 p-r-5 text-center">
							<button class="btn-style1-100" (click)="onRestore(); ">{{ 'Common.Restore' | translate }}</button>
						</div>
						<div class="col-6 p-l-5 text-center">
							<button class="btn-style1-100" (click)="refresh(); ">{{ 'Common.Refresh' | translate }}</button>
						</div>
					</div>
					<table class="table m-t-20 wallet-table">
						<thead>
							<tr>
								<th>{{ 'Common.Game' | translate }}</th>
								<th>{{ 'Common.Amount' | translate }}</th>
								<th class="text-right">{{ 'Common.Action' | translate }}</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let item of providerStack; let ind = index">
								<td>{{ item.name }}</td>
								<td> {{currencyCode }}
									<span *ngIf="showLoading && item.code === currentGameCode" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
									<ng-container *ngIf="item.code !== currentGameCode">{{ (item.balance) ? (item.balance | number : '1.2-2') : ' 0.00' }} </ng-container>
								</td>
								<td class="text-right">
								<button class="btn-style1-sm" [ngClass]="item.status === 2 || isDisabled && item.code === currentGameCode  ? 'disabled' : ''" [disabled]="item.status === 2" (click)="onAllIn(item.id, item.code, ind)">{{ 'Common.AllIn' | translate }}</button>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
</div>

<app-swal-alert [success]="isSuccess" [message]="messages$ | async"></app-swal-alert>
