import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-transfer-wrapper-modal',
  templateUrl: './transfer-wrapper-modal.component.html',
  styleUrls: ['./transfer-wrapper-modal.component.scss']
})
export class TransferWrapperModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<TransferWrapperModalComponent>,
  ) { }

  ngOnInit() { }

  onCloseDialog(event?: Event ) {
    this.dialogRef.close();
  }
}
