import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { Subject } from 'rxjs';
import { AuthRegister } from '@core/models/auth-register.model';
import { tap } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { Location } from '@angular/common';
import { select, Store } from '@ngrx/store';
import { isLoggedIn } from '@core/store/auth/auth.selectors';
import { changeProfile, logout } from '@core/store/auth/auth.actions';



@Injectable()
export class AuthHttpService {


  messages$ = new Subject<any[]>();
  isSuccess: boolean;
  isUserLoggedIn: boolean;

  // Marketing
  marketingStorage = 'marketing_campaign';
  sourceTracker: string;

  constructor(
    private http: HttpClient,
    private store: Store,
    private location: Location
  ) {
    this.store.pipe(select(isLoggedIn)).subscribe(res => this.isUserLoggedIn = res);
  }

  login(username: string, password: string) {
    return this.http.post<ApiResponse>('/login', {username, password}).pipe(
      tap(res => {
        localStorage.removeItem('redirectToLogin');
        this.isSuccess = res.success;
        this.messages$.next(res.message);
      })
    );
  }

  register(value: AuthRegister) {
    let addons: any = { url: (sessionStorage.getItem('signupRoute')? sessionStorage.getItem('signupRoute') : window.location.href) };

    let marketingCampaign = JSON.parse(localStorage.getItem(this.marketingStorage));
    if (marketingCampaign !== null && ('sub2' in marketingCampaign) && marketingCampaign['sub2'].length > 0 && !marketingCampaign['sub2'].startsWith('http'))
    {
      marketingCampaign['sub2'] = 'https://' + marketingCampaign['sub2'];
    }
    const paramsMarketing = marketingCampaign;

    // ONLY get specific query string vars!
    if (paramsMarketing)
    {
      // Marketing vars
      addons = {
        ...addons,
        campaign: paramsMarketing?.campaign,
        cid: paramsMarketing?.cid,
        clickid: paramsMarketing?.clickid,
        aff: paramsMarketing?.aff,
        ref: paramsMarketing?.ref,
        referral_source: paramsMarketing?.referral_source,
      };
    }

    return this.http.post<ApiResponse>('/register', {...value, ...addons}).pipe(
      tap(res => {
        this.isSuccess = res.success;
        this.messages$.next(res.message);
        if (res.success) {

          // Tracker vars
          const paramsTracker = {
            pid: paramsMarketing?.sub3,
            ev: paramsMarketing?.sub4
          };
          if (paramsTracker && Object.keys(paramsTracker)) {
            this.sourceTracker = paramsMarketing?.sub2 + `?` + Object.keys(paramsTracker).map(key => `${key}=${paramsTracker[key]}`).join('&');
            const iframe = document.createElement('iframe');
            iframe.src = this.sourceTracker;
            document.getElementsByTagName('head')[0].appendChild(iframe);
          }

          localStorage.removeItem(this.marketingStorage);
          sessionStorage.removeItem('signupRoute');
        }
      })
    );
  }

  logout() {
    return this.http.get('/logout');
  }

  getUrlParams(search: string) {
    const hashes = search.slice(search.indexOf('?') + 1).split('&');
    return hashes.reduce((params, hash) => {
        const [key, val] = hash.split('=');
        return Object.assign(params, {[key]: decodeURIComponent(val)});
    }, {});
  }

  updateCampaignClickCount(campaign: string){
    return this.http.post<ApiResponse>('/campaign/updateclickcount', { campaign }).pipe(
      tap(res => {
        this.isSuccess = res.success;
      })
    );
  }

  updateAffiliateClickCount(aff: string){
    return this.http.post<ApiResponse>('/affiliate/updateclickcount', { aff }).pipe(
      tap(res => {
        this.isSuccess = res.success;
      })
    );
  }

  forceLowerCaseInputControl(formGroup: FormGroup, formControlName: string, event: Event) {
    formGroup.get(formControlName).setValue((event.target as HTMLInputElement).value.toLowerCase());
  }

  checkTokenExpiration(expiryTime: any) {
    const tokenExpiryDateUTC = moment(expiryTime);
    const currentDateUTC = moment.utc();

    // Private page only
    if (
        this.isUserLoggedIn &&
        (this.location.path()).startsWith('/member') &&
        tokenExpiryDateUTC.diff(currentDateUTC, 'minutes') <= 0
    ) {
      this.store.dispatch(logout());
    }

    if(tokenExpiryDateUTC.diff(currentDateUTC, 'minutes') <= 0) {
      localStorage.removeItem('user_token');
    }

    return false; // Not expired yet!

  }

  // Note: This method must be called when there is private API endpoint
  // iniated from any of the public pages.
  isTokenExpired() {
    const userToken =  JSON.parse(localStorage.getItem('user_token'));

    if(userToken !== null) {
      const expiryDateTime = userToken ? userToken.expiry_datetime : null;
      if(!this.checkTokenExpiration(expiryDateTime)) {
        return false;
      }

    }
    return true;
  }

}
