<div class="sign-in" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
      <!-- <div class="modal-body"> -->
          <div class="modal-content modal-bg-signin m-t-70">
              <div class="modal-header-signin">
                  <h4 class="modal-title-signin"><img src="/assets/images/register.png" width="30" >{{ 'Common.Join' | translate }}</h4>
              </div>
              <div class="modal-body-signin">
                  <form [formGroup]="form" (ngSubmit)="submit(form.value)">
                    <p>{{ 'Auth.Username' | translate }}</p>
                    <input id="focusInput" type="text" formControlName="username" class="form-control" (input)="toLowerCaseInput('username', $event)"/>
                    <p>{{ 'Auth.PhoneNumber' | translate }}</p>
                    <input type="text" formControlName="phone_number" class="form-control"/>
                    <p>{{ 'Auth.Password' | translate }}</p>
                    <div class="input-group mb-4">
                        <input [type]="passwordInput.type" class="form-control m-b-0" formControlName="password" (keyup)="onCheckPassword()" #passwordField/>
                        <div class="input-group-append">
                            <button type="button" (click)="onPasswordMask()" class="input-group-text password-mask fs-13">
                                <i [class]="passwordInput.icon"></i>
                            </button>
                        </div>
                    </div>
                    <p class="d-flex justify-content-between">{{ 'Member.Profile.ConfirmPassword' | translate }} 
                        <span *ngIf="!passwordMatched" class="text-danger">{{ 'Auth.PasswordNotMatched' | translate }}</span>
                    </p>
                    <div class="input-group mb-4">
                        <input [type]="passwordInput.type" class="form-control m-b-0" formControlName="confirm_password" (keyup)="onCheckPassword()" #passwordField/>
                        <div class="input-group-append">
                            <button type="button" (click)="onPasswordMask()" class="input-group-text password-mask fs-13">
                                <i [class]="passwordInput.icon"></i>
                            </button>
                        </div>
                    </div>
                    <p (click)="onCheck()" class="tnc m-l-20 m-b-20"><input [checked]="checkBox" type="checkbox" class="form-check-input" id="exampleCheck1">{{ 'Auth.Confirm' | translate }}</p>
                    <div class="text-center m-b-20 m-t-30">
                        <input type="submit" class="btn-style1 text-uppercase " value="{{'Common.Join' | translate}}">
                    </div>
                    <p class="m-t-20 m-b-20">{{ 'Auth.GuestText3' | translate }} <a routerLink="/login" class="btn-signup"> {{ 'Auth.LoginCaption' | translate }}</a></p>
                  </form>
              </div>
          </div>
      <!-- </div> -->
  </div>
</div>

<app-swal-alert [message]="messages$ | async" [success]="isSuccess" (confirmed)="onRedirectLogin()"></app-swal-alert>
