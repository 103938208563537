import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-swal-alert',
  templateUrl: './swal-alert.component.html',
  styleUrls: ['./swal-alert.component.scss']
})
export class SwalAlertComponent implements OnInit, OnChanges {
  @Input()
  success: any;

  @Input()
  message: any[];

  @Input()
  contentHash: string;

  @Output()
  confirmed = new EventEmitter<boolean>();

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.alert();
  }

  private alert() {
    if ((!!this.message) ) {
      let messages = '<ul>';
      this.message.map((row: any) => messages += `<li>${row}</li>`);
      messages += '</ul>';

      Swal.fire({
        title: '<img class="msg-icon" src="/assets/images/message-icon/' + (this.success === true ? 'green-tick' : 'exclamation') + '.png">',
        html: '<div class="text-center m-t-20">' +
                (this.success === true ? '' : '<p class="system-message">Oops!</p>')  +
                messages +
              '</div>'
      }).then(result => {
        if (result) {
          this.confirmed.emit(true);
        }
      });
    }
  }

}
