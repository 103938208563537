import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { Pagination } from '@core/models/pagination.model';
import { PortalMemberProfile } from '@core/models/portal-member-profile.model';
import { ProfilePassword } from '@core/models/profile-password.model';
import { PortalVerification } from './../models/portal-verification.model';
import { ApiResponse } from '@core/models/api-response.model';
import { concatMap, map, tap } from 'rxjs/operators';
import { changeProfile } from '@core/store/auth/auth.actions';
import { AppState } from '@store/reducers';
import { Store } from '@ngrx/store';

@Injectable()
export class PortalMemberProfileHttpService  {

  pagination: Pagination;
  messages$ = new Subject<any[]>();
  isSuccess: boolean;

  constructor(
    private http: HttpClient,
  ) { }

    setTest() {
      this.messages$.next();
    }
    getTest(): Observable<any> {
      return this.messages$.asObservable();
    }

    getAll(): Observable<PortalMemberProfile[]>{
        return this.http.get<ApiResponse>('/profile').pipe(
          map(res => res.data),
        );
      }

    getWithQuery(pageParam: string): Observable<PortalMemberProfile[]>{
      return this.http.get<ApiResponse>(`/profile?paginate=false${pageParam}`).pipe(
        tap(res => this.paginationInit(res)),
        map(res => res.data)
      );
    }

    getProfile(): Observable<PortalMemberProfile> {
      return this.http.get<ApiResponse>('/profile').pipe(
        map(res => res.data.row),
      );
    }

    updateProfile(profile: PortalMemberProfile): Observable<PortalMemberProfile> {
      return this.http.put<ApiResponse>(`/profile`, profile).pipe(
        tap( res => {
          this.messages$.next(res.message);
          this.isSuccess = res.success;
        }),
        concatMap((entity) => this.http.get(`/profile`).pipe(
          map((row: ApiResponse) => row.data.row),
          tap(res => {

            // Complete or reset
            this.messages$.next(null)
            this.isSuccess = false;
          })
        ))
      );
    }

    updatePassword(profilePassword: ProfilePassword) {
      return this.http.post<ApiResponse>(`/changepassword`, profilePassword).pipe(
        tap( res => {
          this.messages$.next(res.message);
          this.isSuccess = res.success;
        }),
        map(res => res.data)
      );
    }

    updateICVerification(verification: PortalVerification) {
      return this.http.put<ApiResponse>(`/profile/verification`, verification).pipe(
        tap( res => {
          this.messages$.next(res.message);
          this.isSuccess = res.success;
        }),
        map(res => res.data)
      );
    }

    private paginationInit(res: any) {
      if (res) {
        this.pagination = res.data.paginations;
      }
    }
}
