<section id="content">
  <div class="container clearfix top-bar-container">
    <div class="row" id="wallet-bar">
      <div class="col-5 p-l-0 p-r-0" style="display: flex;">
        <div class="wallet-refresh">
          <img [ngClass]="{'refresh-spin': isClicked}" (click)="onRefreshBalance()" src="/assets/images/refresh.png" />
        </div>
        <div class="wallet-amount">
          <h6>
            {{ 'Member.Transfer.MainWallet' | translate }} ({{ memberCurrency }})<br />
            <span>{{ (memberBalance$ | async) | number : '1.2-2' }} &nbsp;</span>
          </h6>
        </div>
      </div>
      <div class="col-7 p-l-0 p-r-0">
        <ul class="wallet-navi">
          <li>
            <a routerLink="/member/deposit" class="btn-deposit">
              <img src="/assets/images/deposit.png" class="blue-deposit" />
              <img src="/assets/images/deposit-hover.png" class="white-deposit" style="display: none;" />
              <h6>{{ 'Common.Deposit' | translate }}</h6>
            </a>
          </li>

          <li>
            <a routerLink="/member/transfer" class="btn-transfer">
              <img src="/assets/images/transfer.png" class="blue-transfer" />
              <img src="/assets/images/transfer-hover.png" class="white-transfer" style="display: none;" />
              <h6>{{ 'Common.Transfer' | translate }}</h6>
            </a>
          </li>
          <li>
            <a routerLink="/member/withdraw" class="btn-withdraw">
              <img src="/assets/images/withdraw.png" class="blue-withdraw" />
              <img src="/assets/images/withdraw-hover.png" class="white-withdraw" style="display: none;" />
              <h6>{{ 'Common.Withdraw' | translate }}</h6>
            </a>
          </li>
          <li>
            <a routerLink="/member/history" class="btn-history">
              <img src="/assets/images/history.png" class="blue-history" />
              <img src="/assets/images/history-hover.png" class="white-history" style="display: none;" />
              <h6>{{ 'Member.History.Title' | translate }}</h6>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="container clearfix">
    <div class="row m-t-10">
      <!-- <div class="col-3 p-l-0" id="user-side-navigation">
        <ul>
          <li routerLinkActive="active" (click)="onSelectSection()">
            <a routerLink="/member/deposit"> <img [src]="activeSectionTab === '/member/deposit' ? '/assets/images/deposit-hover.png' : '/assets/images/deposit.png'" />{{ 'Member.Deposit.Title' |  translate }} </a>
          </li>
          <li routerLinkActive="active" (click)="onSelectSection()">
            <a routerLink="/member/transfer"> <img [src]="activeSectionTab === '/member/transfer' ? '/assets/images/transfer-hover.png' : '/assets/images/transfer.png'" />{{ 'Member.Transfer.Title' |  translate }} </a>
          </li>
          <li routerLinkActive="active" (click)="onSelectSection()">
            <a routerLink="/member/withdraw"> <img [src]="activeSectionTab === '/member/withdraw' ? '/assets/images/withdraw-hover.png' : '/assets/images/withdraw.png'" />{{ 'Member.Withdraw.Title' |  translate }} </a>
          </li>
          <li routerLinkActive="active" (click)="onSelectSection()">
            <a routerLink="/member/history"> <img [src]="activeSectionTab === '/member/history' ? '/assets/images/history-hover.png' : '/assets/images/history.png'" />{{ 'Member.History.Title' |  translate }} </a>
          </li>
          <li routerLinkActive="active" (click)="onSelectSection()">
            <a routerLink="/member/profile"> <img [src]="activeSectionTab === '/member/profile' ? '/assets/images/profile-hover.png' : '/assets/images/profile.png'" />{{ 'Member.Profile.Title' |  translate }} </a>
          </li>
          <li routerLinkActive="active" (click)="onSelectSection()">
            <a routerLink="/member/bank"> <img [src]="activeSectionTab === '/member/bank' ? '/assets/images/bank-hover.png' : '/assets/images/bank.png'" />{{ 'Member.BankAccount.Title' |  translate }} </a>
          </li>
          <li routerLinkActive="active" (click)="onSelectSection()">
            <a routerLink="/member/message"> <img [src]="activeSectionTab === '/member/message' ? '/assets/images/message-hover.png' : '/assets/images/message.png'" />{{ 'Member.Message.Title' |  translate }} </a>
          </li>
        </ul>
      </div> -->
      <div class="col-12">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</section>

<app-footer-wrapper></app-footer-wrapper>
