<div class="content-wrap nommargin nopadding">
  <div class="col_full p-3">
    <h2 class="pg-title m-t-70">{{ 'Page.ContactUs.Title' | translate  }}</h2>
    <ul class="contact-us-list">
      <li *ngFor="let item of contactList$ | async">
        <img [src]="'/assets/images/social-icon/'+ item.image_icon" onerror="this.src='/assets/images/social/live-chat.png'" class="contact-icon" />{{ item.contact_type.name }}: {{ item.value }}
      </li>
    </ul>
    <div>
      <h4 class="pg-subtitle">{{ 'Page.ContactUs.Heading' | translate }}</h4>
      <p class="formtext">{{ 'Page.ContactUs.Content' | translate }}</p>
    </div>
  </div>
</div>
