import { EventEmitterService } from '@core/services/event-emitter.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { NumberVerificationHttpService } from '@core/services/number-verification-http.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-verification-dialog',
  templateUrl: './verification-dialog.component.html',
  styleUrls: ['./verification-dialog.component.scss']
})
export class VerificationDialogComponent implements OnInit {
  
  form: FormGroup;
  messages$ = this.numberVerificationHttpService.messages$;
  isSuccess = this.numberVerificationHttpService.isSuccess;
  toCloseDialog = false;
  counter: number;
  timer: any;
  disabled = '';
  constructor(
    public dialogRef: MatDialogRef<VerificationDialogComponent>,
    private numberVerificationHttpService: NumberVerificationHttpService,
    private eventEmitterService: EventEmitterService
  ) { }

  ngOnInit(){
    this.formInit();
    if (sessionStorage.getItem('OTP_timer') !== null){
      this.onTimer(Number(sessionStorage.getItem('OTP_timer')));
    }
  }

  onCloseDialog(toClose: boolean) {
    if (toClose){
      this.dialogRef.close();
    }
  }

  onRequestOTP() {
    this.disabled = 'disabled';
    this.numberVerificationHttpService.requestOTP().subscribe(res => {
      this.onTimer(60);
      this.isSuccess = true
    });
  }

  onVerify() {
    this.numberVerificationHttpService.verifyOTP(this.form.value).subscribe( () => {
      this.toCloseDialog = true;
      localStorage.setItem('verified_number', 'true');
      this.isSuccess = true
    });
  }

  onTimer(startTime: number) {
    this.disabled = 'disabled';
    this.counter = startTime;
    this.eventEmitterService.onVerificationTimerEmitter();
      window.clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.counter--;
        if(this.counter === 0) {
            window.clearInterval(this.timer);
            this.disabled = '';
        }
     }, 1000);
  }

  private formInit() {
    this.form = new FormGroup({
      otp: new FormControl(null, [Validators.required])
    });
  }

}
