import { VerificationDialogComponent } from './../views/pages/portal/subcomponents/portal-withdraw/verification-dialog/verification-dialog.component';
import { BankModalComponent } from './../views/pages/portal/subcomponents/portal-bank/bank-modal/bank-modal.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import '@angular/compiler';

import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CurrencyDirective } from '../core/directives/currency.directive';
import { RemoveWrapperDirective } from '../core/directives/remove-wrapper.directive';
import { DatePipe } from '../core/pipes/date.pipe';
import { LoginModalComponent } from './login-modal/login-modal.component';
import { LanguageModalComponent } from './language-modal/language-modal.component';
import { SignupModalComponent } from './signup-modal/signup-modal.component';
import { ApiInterceptor } from '@core/interceptors/api.interceptor';
import { ApiErrorInterceptor } from '@core/interceptors/api-error.interceptor';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatMenuModule} from '@angular/material/menu';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCardModule} from '@angular/material/card';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTabsModule} from '@angular/material/tabs';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import { SwalAlertComponent } from './swal-alert/swal-alert.component';
import { WalletModalComponent } from './wallet-modal/wallet-modal.component';

import { GameCategoryEntityService } from '@core/services/games/gamecategory-entity.service';
import { GameListEntityService } from '@core/services/games/gamelist-entity.service';
import { GameCategoryResolver } from '@core/resolvers/gamecategory.resolver';
import { GameListResolver } from '@core/resolvers/gamelist.resolver';
import { GameListDataService } from '@core/services/games/gamelist-data.service';
import { GameCategoryDataService } from '@core/services/games/gamecategory-data.service';

import { PortalTransferHttpService } from '@core/services/portal-transfer-http.service';
import { TransferWrapperModalComponent } from './transfer-wrapper-modal/transfer-wrapper-modal.component';
import { TransferModalComponent } from '@views/pages/portal/subcomponents/portal-transfer/dialogs/transfer-edit/transfer-modal.component';
import { BankWrapperModalComponent } from './bank-wrapper-modal/bank-wrapper-modal.component';
import { LoggedValidatorComponent } from './logged-validator/logged-validator.component';
import { ContentModalComponent } from './content-modal/content-modal.component';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { ContentDetailsComponent } from './content-modal/dialog/content-details/content-details.component';

import { NgMarqueeModule } from 'ng-marquee';
import { TinypreloaderComponent } from './tinypreloader/tinypreloader.component';
import { ProcessingTransactionComponent } from './processing-transaction/processing-transaction/processing-transaction.component';

const modules = [

  CommonModule,
  HttpClientModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  MatCheckboxModule,
  MatCheckboxModule,
  MatButtonModule,
  MatInputModule,
  MatAutocompleteModule,
  MatDatepickerModule,
  MatFormFieldModule,
  MatRadioModule,
  MatSelectModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatMenuModule,
  MatSidenavModule,
  MatToolbarModule,
  MatListModule,
  MatGridListModule,
  MatCardModule,
  MatStepperModule,
  MatTabsModule,
  MatExpansionModule,
  MatButtonToggleModule,
  MatChipsModule,
  MatIconModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatDialogModule,
  MatTooltipModule,
  MatSnackBarModule,
  MatTableModule,
  MatSortModule,
  MatPaginatorModule,
  NgbModule,
  DigitOnlyModule,
  NgMarqueeModule,
  TranslateModule
];

const directives = [
  CurrencyDirective,
  RemoveWrapperDirective,
];
const pipes = [
  DatePipe
];

const dialogs = [
  LoginModalComponent,
  SignupModalComponent,
  WalletModalComponent,
  LanguageModalComponent,
  TransferWrapperModalComponent,
  BankWrapperModalComponent,
  ContentDetailsComponent,
  VerificationDialogComponent
];

const components = [
  ...directives,
  ...pipes,
  ...dialogs,
  SwalAlertComponent,
  TransferModalComponent,
  BankModalComponent,

  LoggedValidatorComponent, // TODO: Remove and use swal modal instead!

  TransferModalComponent,
  TransferWrapperModalComponent,
  BankModalComponent,
  BankWrapperModalComponent,
  ContentModalComponent,
  ContentDetailsComponent,
  TinypreloaderComponent,
  ProcessingTransactionComponent
];

const services = [
  GameListEntityService,
  GameListDataService,
  GameListResolver,
  GameCategoryEntityService,
  GameCategoryDataService,
  GameCategoryResolver,
  PortalTransferHttpService,
];

@NgModule({
  declarations: [
    ...components,
  ],
  exports: [
    ...components,
    ...modules,
  ],
  imports: [
    ...modules
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiErrorInterceptor, multi: true },
    ...services
  ],
  entryComponents: [
    ...dialogs
  ]
})
export class SharedModule {
  static forRoot() {
    return {
      ngModule: SharedModule,
      providers: []
    };
  }
}
