<app-pre-loader *ngIf="(loadingBar.progress$|async) > 0"></app-pre-loader>
<ng-container *ngIf="!isDesktopDetected">
  <div class="body-overlay" (click)="onHideSideBar()"></div>
  <div *ngIf="!isLanding" id="primary-menu-trigger" class="side-panel-trigger side-bar-icon">
    <app-sidepanels id="side-panel" class="dark"></app-sidepanels>
    <svg class="svg-trigger" viewBox="0 0 100 100">
        <path d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"></path>
        <path d="m 30,50 h 40"></path>
        <path d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"></path>
    </svg>
  </div>

  <div *ngIf="isLanding" id="primary-menu-trigger" class="side-panel-trigger side-bar-icon">
      <button type="button" class="close" (click)="onClose()">&times;</button>
  </div>

  <app-topbar *ngIf="!isLanding"></app-topbar>
  <app-base-inner>
    <router-outlet></router-outlet>
  </app-base-inner>

</ng-container>
