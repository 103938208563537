import { Component, OnInit } from '@angular/core';
import { AuthData } from '@core/models/auth-data';
import { DashboardService } from '@core/services/dashboard.service';
import { WalletHttpService } from '@core/services/wallet-http.service';
import { isLoggedIn, loggedUser } from '@core/store/auth/auth.selectors';
import { select, Store } from '@ngrx/store';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { AppState } from '@store/reducers';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthHttpService } from '@core/services/auth-http.service';
import { logout } from '@core/store/auth/auth.actions';

@Component({
  selector: 'app-wallet-bar',
  templateUrl: './wallet-bar.component.html',
  styleUrls: ['./wallet-bar.component.scss']
})
export class WalletBarComponent implements OnInit {



  memberBalance$ = this.dashboardService.memberBalance$;
  memberUsername$ = this.dashboardService.memberUsername$;

  memberCurrency = '';

  loggedUser$: Observable<AuthData>;
  isLoggedIn$: Observable<boolean>;
  isClicked =  false;

  constructor(
    private dashboardService: DashboardService,
    private walletHttpService: WalletHttpService,
    private store: Store<AppState>,
    public loadingBar: LoadingBarService,
    private authService: AuthHttpService
  ) { }

  ngOnInit() {
    this.loggedUser$ = this.store.pipe(
      select(loggedUser),
      tap((user) => {
        this.dashboardService.refreshMemberName(user.username);
      })
    );
    this.isLoggedIn$ = this.store.pipe(select(isLoggedIn));
    this.loggedUser$.subscribe(res => {
      if (res !== null) {
        this.setWalletDetails();
      }
    });
  }


  onRefreshBalance(){
    this.isClicked = true;
    this.setWalletDetails();
  }

  get isTokenExpired() {
    if(!this.authService.isTokenExpired()){
      return false;
    }
    this.store.dispatch(logout());
    localStorage.setItem('redirectToLogin', '1');
    return true;
  }

  private setWalletDetails(){
    if(sessionStorage.getItem('initialLogin') !== '1'){
      if(!this.authService.isTokenExpired()) {
        this.walletHttpService.getMemberBalance().pipe(
          tap((res) => {
            this.dashboardService.refreshMemberBalance(res.balance);
            this.memberCurrency = res.currency_code ? res.currency_code : '$';
            this.isClicked = false;
          })
        ).subscribe();
      }
    }
  }

}
