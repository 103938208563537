import { EventEmitterService } from '@core/services/event-emitter.service';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from '@store/reducers';
import { isLoggedIn } from '@core/store/auth/auth.selectors';
import { MatDialog } from '@angular/material/dialog';
import { LoginModalComponent } from '@shared/login-modal/login-modal.component';
import { SignupModalComponent } from '@shared/signup-modal/signup-modal.component';
import { WalletModalComponent } from '@shared/wallet-modal/wallet-modal.component';
import { LanguageModalComponent } from '@shared/language-modal/language-modal.component';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { LiveChatIdHttpService } from '@core/services/live-chat-id-http.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-footer-wrapper',
  templateUrl: './footer-wrapper.component.html',
  styleUrls: ['./footer-wrapper.component.scss']
})
export class FooterWrapperComponent implements OnInit {

  isLoggedIn$: Observable<boolean>;
  languages = this.dropdownHttpService.languageCode;
  isAuthStandAlone = false;
  counter: number;
  timer: any;
  showSeconds = false;

  constructor(
    public dialog: MatDialog,
    private store: Store<AppState>,
    private dropdownHttpService: DropdownHttpService,
    private liveChatIdHttpService: LiveChatIdHttpService,
    private route: ActivatedRoute,
    private eventEmitterService: EventEmitterService
  ) { }

  ngOnInit() {
    if (this.route.routeConfig.component.name === 'AuthComponent') {
      this.isAuthStandAlone = true;
    }
    this.isLoggedIn$ = this.store.pipe(select(isLoggedIn));
    this.eventEmitterService.verificationTimerEmitter.subscribe((name: string) => {
      if (sessionStorage.getItem('OTP_timer') !== null){
        this.onTimer(Number(sessionStorage.getItem('OTP_timer')) - 1);
      }else{
        this.onTimer(60 - 1);
      }
    });
  }

  onTimer(startTime: number) {
    this.counter = startTime;
      window.clearInterval(this.timer);
      this.timer = setInterval(() => {
        sessionStorage.setItem('OTP_timer', (this.counter--).toString());
        if(this.counter === 0) {
            window.clearInterval(this.timer);
            this.showSeconds = false;
            sessionStorage.removeItem('OTP_timer');
        }
     }, 1000);
  }

  onOpenDialog(type: string) {

    switch (type) {
      case 'login':
        this.openDialogBy(LoginModalComponent, 'login');
        break;

      case 'signup':
        this.openDialogBy(SignupModalComponent, 'signup');
        break;

      case 'wallet':
        this.openDialogBy(WalletModalComponent, 'wallet');
        break;

      case 'language':
        this.openDialogBy(LanguageModalComponent, 'language');
        break;
    }

  }

  private openDialogBy(componentRef: any, type: string) {
    if (type !== 'language'){
      this.dialog.open(componentRef, {
        width: '800px',
      });
    } else {
      this.dialog.open(componentRef);
    }
  }

  openLiveChat(){
    const languageCode = localStorage.getItem('language_code');
    const countrycode = localStorage.getItem('country_code');
    window.open(this.liveChatIdHttpService.getUrl(countrycode, languageCode));
  }

}
