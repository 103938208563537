import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GameCategoryHttpService } from '@core/services/game-category-http.service';
import { GameProviderHttpService } from '@core/services/game-provider-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { TransferWrapperModalComponent } from '@shared/transfer-wrapper-modal/transfer-wrapper-modal.component';
import { Subject, Subscription } from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-content-details',
  templateUrl: './content-details.component.html',
  styleUrls: ['./content-details.component.scss']
})
export class ContentDetailsComponent implements OnInit {

  memberUser$ = new Subject<any>();
  private subscription = new Subscription();

  playtechLanguage = 'en';
  playtechClient: string;
  playtechGameMode = 'real';
  username: string;
  password: string;
  activeSlotIndex = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      header: string,
      image: string,
      description: string;
      content: string,
      buttonText: string,
      contentHash: string,
      item: any,
      categoryCode: string,
      type: string,
      isPlayTechClicked: boolean,
      isGameProviderParent: boolean,
      gameProviderType: string,
      gameProviderIndex: number,
    },
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ContentDetailsComponent>,
    private gameCategoryHttpService: GameCategoryHttpService,
    private gameProviderHttpService: GameProviderHttpService,
    public loadingBar: LoadingBarService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.data.content = this.data.content.replace(/\&lt;/g, '<').replace(/\&gt;/g, '>');
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }


  onPlayGame(gameCategory: string, gameProvider: any) {
    this.onCloseDialog();
    if (localStorage.getItem('user_data') === null){
      return this.memberUser$.next('Please login to continue');
    }

    if (this.data.isGameProviderParent) {

      let slug = '';
      switch (this.data.categoryCode) {
        case 'LC': slug = '/casino'; break;
        case 'SL': slug = '/slots'; break;
        case 'SP': slug = '/sports'; break;
        case 'FS': slug = '/fishing'; break;
      }

      return this.router.navigate([slug], {
        state: {
          gameProviderCategory: this.data.categoryCode,
          gameProviderCode: this.data.item.game_provider_code,
          gameProviderType: this.data.gameProviderType,
          gameProviderIndex: this.data.gameProviderIndex
        }
      });
    }

    if (gameProvider.type === 'LIST') {
      switch (this.data.categoryCode) {
        case 'SL':  this.router.navigate(['/slots']); break;
        case 'LC':  this.router.navigate(['/casino']); break;
        case 'SP':  this.router.navigate(['/sports']); break;
        case 'FS':  this.router.navigate(['/fishing']); break;
      }
    } else {
      this.loadingBar.start();
      this.subscription = this.gameCategoryHttpService.getCategoryList().pipe(
        tap((row: any) => {
          row.map((data: any) => {
            if (gameProvider.game_provider_code === data.code && gameProvider.game_provider_name === data.name){
              return this.gameProviderHttpService.launchGame(`?category_code=${gameCategory}&game_provider_code=${gameProvider.game_provider_code}`).pipe(
                tap((res: any) => {
                  this.username = res.username;
                  this.password = res.password;
                  if (data.balance <= 0) {
                    this.loadingBar.complete();
                    Swal.fire({
                      html: '<i class="icon-line2-check blue-icon"></i>' +
                            '<div class="text-center m-t-20">' +
                              '<ul><li>You do not have any balance in game wallet, would you like to transfer now?</li></ul>' +
                            '</div>',
                        showCancelButton: true,
                        confirmButtonText: 'OK',
                        reverseButtons: true,
                        cancelButtonText: 'No',
                      }).then(result => {
                      if (result.value) {
                        this.openDialogBy(TransferWrapperModalComponent, { provider: gameProvider, gameUrl: res ? res.url : null });
                      }else{
                        if (this.data.isPlayTechClicked) {
                          this.login();
                          localStorage.setItem('playtechCategory', 'casino');
                          localStorage.setItem('playtechUsername', res.username);
                          localStorage.setItem('playtechPassword', res.password);
                        } else {
                          localStorage.setItem('playtechCategory', 'singleGame');
                          localStorage.setItem('gameURL', res.url);
                        }
                        this.router.navigate([]).then( () => { window.open('/gamelaunch', '_blank'); });
                      }
                    });
                  }else{
                    this.loadingBar.complete();
                    if (this.data.isPlayTechClicked) {
                      this.login();
                      localStorage.setItem('playtechCategory', 'casino');
                      localStorage.setItem('playtechUsername', res.username);
                      localStorage.setItem('playtechPassword', res.password);
                    } else {
                      localStorage.setItem('playtechCategory', 'singleGame');
                      localStorage.setItem('gameURL', res.url);
                    }
                    this.router.navigate([]).then( () => { window.open('/gamelaunch', '_blank'); });
                  }
                })
              ).subscribe();
            }
          });
        })
      ).subscribe();
    }
  }

  login() {
    const { isDesktop } = JSON.parse((localStorage.getItem('user_browser')));
    const platform = isDesktop ? 'web' : 'mobile';

    // Get variables
    const username = this.username;
    const password = this.password;
    const lang = this.playtechLanguage;
    const mode = this.playtechGameMode;

    if (mode === 'real') {
      iapiSetClientType('casino');
      iapiSetClientPlatform(platform);
      iapiLogin(username, password, 1, lang);
    } else {
      // mode is offline, which does not require login. NOTE: only supports client with ngm_desktop and ngm_mobile
      launchGameWithFunMode();
    }
  }

  private openDialogBy(componentRef: any, data?: { provider?: any, gameUrl?: string }) {
    this.dialog.open(componentRef, {
      width: '800px',
      data: {
        provider: data.provider,
        gameUrl: data.gameUrl
      }
    });
  }

 onSlotsPage(index?: number) {
    this.onCloseDialog();
    this.router.navigate(['/slots'], { state: { activeCategory: this.data.item, providerCode: this.data.item.game_provider_code, typeFromHome: this.data.item.type, indexFromHome: this.data.gameProviderIndex  } } );

  }

  onFishingPage() {
    this.onCloseDialog();
    this.router.navigate(['/fishing'], { state: { activeCategory: this.data.item, providerCode: this.data.item.game_provider_code, indexFromHome: this.data.gameProviderIndex } });
  }

}
