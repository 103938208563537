import { Injectable, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';

@Injectable()
export class EventEmitterService {

  reloadMessageEmitter = new EventEmitter();
  reloadBankListEmitter = new EventEmitter();
  reloadUnreadMessageEmitter = new EventEmitter();
  selectInputEmitter = new EventEmitter();
  removeBodyClassEmitter = new EventEmitter();
  openVerificationEmitter = new EventEmitter();
  verificationTimerEmitter = new EventEmitter();
  bankListVar: Subscription;
  subsVar: Subscription;
  unreadMessageVar: Subscription;
  removeBodyClassVar: Subscription;

  constructor() { }

  onReloadMessage() {
    this.reloadMessageEmitter.emit();
  }

  onReloadBankList() {
    this.reloadBankListEmitter.emit();
  }

  onReloadUnreadMessag() {
    this.reloadUnreadMessageEmitter.emit();
  }

  onselectInput() {
    this.selectInputEmitter.emit();
  }

  onRemoveBodyClass() {
    this.removeBodyClassEmitter.emit();
  }

  onOpenVerificationEmitter() {
    this.openVerificationEmitter.emit();
  }

  onVerificationTimerEmitter() {
    this.verificationTimerEmitter.emit();
  }
}
