<div class="content-wrap nommargin nopadding">

  <div class="col_full p-3">

    <h2 class="pg-title m-t-70">{{'Page.AboutUs.Title' | translate }}</h2>
    <p class="formtext">{{'Page.AboutUs.Content.Overview.P1' | translate }}
      <br><br> {{'Page.AboutUs.Content.Overview.P2' | translate }}
    </p>

    <h3 class="pg-subtitle"> {{ 'Page.AboutUs.Content.Section1.Heading' | translate }}</h3>
    <p class="formtext"> {{'Page.AboutUs.Content.Section1.Text' | translate }}</p>

    <h3 class="pg-subtitle"> {{'Page.AboutUs.Content.Section2.Heading' | translate }}</h3>
    <p class="formtext"> {{'Page.AboutUs.Content.Section2.Text' | translate }}</p>

    <h3 class="pg-subtitle"> {{'Page.AboutUs.Content.Section3.Heading' | translate }}</h3>
    <p class="formtext"> {{'Page.AboutUs.Content.Section3.Text' | translate }}</p>

    <h3 class="pg-subtitle"> {{'Page.AboutUs.Content.Section4.Heading' | translate }}</h3>
    <p class="formtext"> {{'Page.AboutUs.Content.Section4.Text' | translate }}</p>

    <h3 class="pg-subtitle"> {{'Page.AboutUs.Content.Section5.Heading' | translate }}</h3>
    <p class="formtext"> {{'Page.AboutUs.Content.Section5.Text.P1' | translate }}
      <br><br> {{'Page.AboutUs.Content.Section5.Text.P2' | translate }}
    </p>

  </div>
</div>