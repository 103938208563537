<div class="modal1" id="modal-signup">
  <div class="block mx-auto ma-box boxshadow">
    <div class="row">
      <div class="col-12">
        <div class="content bank-modal-content">
          <div class="modal show sign-in slideUp-content" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-body">
                    <div class="modal-content modal-bg">
                        <div class="modal-header">
                            <h4 class="modal-title" id="myModalLabel">{{ 'NumberVerification.NumberVerification' | translate }} </h4>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="onCloseDialog(true)">×</button>
                        </div>
                        <form [formGroup]="form" class="p-25">
                          <div class="user-input">
                            <label>{{ 'NumberVerification.PLEASEVERIFYTEXT' | translate }} <br></label>
        
                            <input type="text" class="form-control" placeholder="{{ 'NumberVerification.OTP' | translate }}" formControlName="otp" />
        
                          </div>

                          <div class="m-t-30 d-flex justify-content-between">
                            <button type="button" class="btn-style1 pl-3 pr-3" (click)="onVerify()">{{ 'Common.Save' | translate }} </button>
                            <button type="button" [class]="'btn-style1 pl-3 pr-3 ' + disabled" (click)="onRequestOTP()" [disabled]="counter > 0">
                              {{ 'NumberVerification.REQUESTANOTP' | translate }}
                              <span *ngIf="counter > 0"> ({{this.counter}})</span>
                            </button>
                          </div>
                        </form>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-swal-alert [success]="isSuccess" [message]="messages$ | async" (confirmed)="onCloseDialog(toCloseDialog)"></app-swal-alert>