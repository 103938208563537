import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { GameList } from '../../models/games/game-list.model';

@Injectable()
export class GameListEntityService extends EntityCollectionServiceBase<GameList> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('GameList', serviceElementsFactory);
    }
}

