import { Directive } from '@angular/core';

@Directive({
  selector: '[appCurrency]'
})
export class CurrencyDirective {

  constructor() { }

}
