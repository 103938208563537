import { LoadingBarService } from '@ngx-loading-bar/core';
import { Component, OnInit, AfterViewInit, Inject, OnDestroy } from '@angular/core';
import { AuthHttpService } from '@core/services/auth-http.service';
import { Subscription } from 'rxjs';
import * as fromAuthActions from '@core/store/auth/auth.actions';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { AppState } from '@store/reducers';
import { tap } from 'rxjs/operators';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthRegister } from '@core/models/auth-register.model';
import { DOCUMENT, Location } from '@angular/common';
import Swal from 'sweetalert2';
import { isLoggedIn } from '@core/store/auth/auth.selectors';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit, AfterViewInit, OnDestroy {

  messages$ = this.auth.messages$;
  isSuccess = this.auth.isSuccess;
  form: FormGroup;
  passwordInput = {
    isMasked: true,
    type: null,
    icon: null,
    isValidPassword: true
  };

  // Marketing
  checkBox = false;
  sourceTracker: string;
  campaignCode: string;
  affiliateCode: string;
  clickid: string;
  cid: string;
  passwordMatched = true;

  private unsubscribe: Subscription[] = [];

  constructor(
    private location: Location,
    private router: Router,
    private auth: AuthHttpService,
    private store: Store<AppState>,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    public loadingBar: LoadingBarService,
    @Inject(DOCUMENT) private document: any
  ) { }

  ngOnInit() {
    this.onPasswordMask();
    const hotjarSubscription = this.store.pipe(select(isLoggedIn)).subscribe(res => {
      if (!res) {
        const script = document.createElement('script');
        script.src = '/assets/integrations/hotjar-tracker.js';
        document.getElementsByTagName('head')[0].appendChild(script);
      } else {
        document.body.classList.add('logged-in');
      }
    });
    this.unsubscribe.push(hotjarSubscription);

    this.route.queryParams.subscribe((params: Params) => {
      this.campaignCode = params[`campaign`];
      this.affiliateCode = params[`aff`];
      this.clickid = params[`clickid`];
      this.cid = params[`cid`];
      if (Object.keys(params).length > 0){
        sessionStorage.setItem('signupRoute', window.location.href);
      }
    });
    if (this.campaignCode){
      this.auth.updateCampaignClickCount(this.campaignCode).subscribe();
    }
    if (this.affiliateCode){
      this.auth.updateAffiliateClickCount(this.affiliateCode).subscribe();
    }
    this.initializeForm();
    document.getElementById('focusInput').focus();
  }

  ngAfterViewInit() {
    const locationSearch = window.location.search;
    if (locationSearch) {
      localStorage.setItem(this.auth.marketingStorage, JSON.stringify(this.auth.getUrlParams(locationSearch) as any));
      this.location.replaceState(this.router.url.split('?')[0]);
    }
  }

  onPasswordMask() {
    this.passwordInput.isMasked = !this.passwordInput.isMasked;
    this.passwordInput.type = this.passwordInput.isMasked ? 'text' : 'password';
    this.passwordInput.icon = this.passwordInput.isMasked ? 'icon-eye' : 'icon-eye-slash';
  }

  onCheckPassword(){
    this.passwordMatched = true;
    if (this.form.value.confirm_password !== null && this.form.value.password !== null){
      if(this.form.value.password !== this.form.value.confirm_password){
        this.passwordMatched = false;
      }

      if (this.form.value.password.length === 0 || this.form.value.confirm_password.length === 0){
        this.passwordMatched = true;
      }
    }
  }

  onRedirectLogin() {
    this.router.navigate(['/login']);
  }

  onCheck(){
    if ( this.checkBox === true){
      this.checkBox = false;
      this.onAgree();
    }else{
      this.checkBox = true;
      this.onAgree();
    }
  }

  ngOnDestroy() {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
  }

  submit(value: AuthRegister) {
    if (this.form.value.confirm_terms) {
      this.loadingBar.start();
      this.auth.register(value).pipe(
        tap( () => {
          this.store.dispatch(fromAuthActions.register());
          this.loadingBar.complete();
        })
      )
      .subscribe(() =>  this.isSuccess = this.auth.isSuccess);
    }else{
      Swal.fire({
        html: '<i class="icon-exclamation-triangle alert-icon-fail"></i>' +
              '<div class="text-center m-t-20">' +
                '<ul><li>Please agree on Terms & Conditions</li></ul>' +
              '</div>',
          confirmButtonText: 'Close',
      });
    }
  }

  onAgree() {
    this.form.patchValue({
      confirm_terms : this.form.value.confirm_terms ? null : 1
    });
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.auth.forceLowerCaseInputControl(this.form, controlName, event);
  }

  private initializeForm() {
    this.form = this.fb.group({
      username: [
        null,
        [Validators.required]
      ],
      phone_number: [
        null,
        [Validators.required]
      ],
      confirm_terms: [
        null,
        [Validators.required]
      ],
      password: [
        null,
        [Validators.required]
      ],
      confirm_password: [
        null,
        [Validators.required]
      ]
    });
  }

}
