import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgModule } from '@angular/core';

import { BaseComponent } from './base/base.component';
import { BottombarComponent } from './partials/bottombar/bottombar.component';
import { SidebarComponent } from './partials/sidebar/sidebar.component';
import { TopbarComponent } from './partials/topbar/topbar.component';
import { BaseInnerComponent } from './partials/base-inner/base-inner.component';
import { SharedModule } from '../shared/shared.module';
import { SliderComponent } from './partials/slider/slider.component';
import { SidePanelsComponent } from './partials/sidepanels/sidepanels.component';
import { PortalMessageHttpService } from '@core/services/portal-message-http.service';
import { LivechatWidgetModule } from '@livechat/angular-widget';
import { PortalMemberProfileHttpService } from '@core/services/portal-member-profile-http.service';
import { FooterWrapperComponent } from './partials/footer-wrapper/footer-wrapper.component';
import { GameMenuComponent } from './partials/game-menu/game-menu.component';
import { WalletBarComponent } from './partials/wallet-bar/wallet-bar.component';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';

const partials = [
  BaseComponent,
  BaseInnerComponent,
  BottombarComponent,
  SidebarComponent,
  TopbarComponent,
  SliderComponent,
  SidePanelsComponent,
  FooterWrapperComponent,
  GameMenuComponent,
  WalletBarComponent
];

const components = [
  ...partials
];

@NgModule({
  declarations: [
    ...components
  ],
  exports: [
    ...components
  ],
  imports: [
    SharedModule,
    LivechatWidgetModule,
    NgxUsefulSwiperModule
  ],
  providers: [
    PortalMessageHttpService,
    PortalMemberProfileHttpService
  ]
})
export class ViewsModule { }
