import { BankAccount } from '@core/models/bank-account.model';
import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';

@Injectable()
export class BankAccountEntityService extends EntityCollectionServiceBase<BankAccount> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
      super('BankAccount', serviceElementsFactory);
  }
}
