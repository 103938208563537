<div id="primary-menu-trigger" class="side-panel-trigger side-bar-icon" (click)="onHideSideBar()">
    <svg class="svg-trigger" viewBox="0 0 100 100">
        <path d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"></path>
        <path d="m 30,50 h 40"></path>
        <path d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"></path>
    </svg>
</div>

<header id="header" class="full-header" data-sticky-class="not-dark">
  <div id="header-wrap" >
    <div class="container">
        <div class="header-row">

            <div id="logo">
                <a routerLink="/" class="standard-logo" data-dark-logo="/assets/images/logo.png"><img src="/assets/images/logo.png"></a>
                <a routerLink="/" class="retina-logo" data-dark-logo="/assets/images/logo.png"><img src="/assets/images/logo.png"></a>
            </div>

            <div class="header-misc" *ngIf="(isLoggedIn$ | async) !== false || null || undefined">
                <div class="header-misc-icon">
                    <a routerLink="/member/message" style="position:relative">
                        <img src="/assets/images/message-hover.png" class="bell">
                        <span *ngIf="(unreadMessages$ | async) as unread" class="notification-count">{{ unread }}</span>
                    </a>
                </div>
            </div>
            <div style="width:25px;height:25px; margin-left: 26px"  *ngIf="(isLoggedIn$ | async) === false || null || undefined">
            </div>

        </div>
    </div>
  </div>
  <div class="header-wrap-clone"></div>
</header>
