import { Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { isLoggedIn, loggedUser } from '@core/store/auth/auth.selectors';
import { AppState } from '@store/reducers';
import { Store, select } from '@ngrx/store';
import { AuthData } from '@core/models/auth-data';
import { tap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { WalletHttpService } from '@core/services/wallet-http.service';
import { Observable } from 'rxjs';
import { DashboardService } from '@core/services/dashboard.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.scss']
})
export class PortalComponent implements OnInit {


  memberBalance$ = this.dashboardService.memberBalance$;
  memberUsername$ = this.dashboardService.memberUsername$;

  memberCurrency = '';
  activeSectionTab = this.router.url;

  loggedUser$: Observable<AuthData>;
  isLoggedIn$: Observable<boolean>;
  isClicked = false;

  user_data = JSON.parse(localStorage.getItem('user_data'));

  constructor(
    private dashboardService: DashboardService,
    private walletHttpService: WalletHttpService,
    private store: Store<AppState>,
    public loadingBar: LoadingBarService,
    private router: Router,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.loggedUser$ = this.store.pipe(
      select(loggedUser),
      tap((user) => {
        this.dashboardService.refreshMemberName(user.username);
      })
    );
    this.isLoggedIn$ = this.store.pipe(select(isLoggedIn));
    if(sessionStorage.getItem('initialLogin') === '1'){
      sessionStorage.setItem('initialLogin','0');
    }
    this.isLoggedIn$.subscribe(res => {
      if (res === true) {
        this.setWalletDetails();
      }
    });
    this.translateService.use((localStorage.getItem('language_code').toLowerCase()).toLocaleLowerCase());
  }

  onSelectSection() {
    this.activeSectionTab = this.router.url;
  }

  onRefreshBalance(){
    this.isClicked = true;
    this.setWalletDetails();
  }

  private setWalletDetails(){
    this.walletHttpService.getMemberBalance().pipe(
      tap((res) => {
        this.dashboardService.refreshMemberBalance(res.balance);
        this.memberCurrency = res.currency_code ? res.currency_code : '$';
        this.isClicked = false;
      })
    ).subscribe();
  }

}
