import { ApiResponse } from '../models/api-response.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { GameCategory } from '@core/models/games/game-category.model';
import { GameCategoryList } from '@core/models/games/game-category-list.model';

@Injectable()
export class GameCategoryHttpService {

  constructor(private http: HttpClient) { }

  getGames(code: string): Observable<GameCategory[]> {
    return this.http.get<ApiResponse>(`/gameprovider/gamelistbycategory?category_code=${code}`).pipe(
      map(res => res.data.rows)
    );
  }

  getCategoryList(): Observable<GameCategoryList[]> {
    return this.http.get<ApiResponse>(`/gameprovider/list`).pipe(
      map(res => res.data.rows)
    );
  }

}
