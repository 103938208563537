import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContentDetailsComponent } from './dialog/content-details/content-details.component';

@Component({
  selector: 'app-content-modal',
  templateUrl: './content-modal.component.html',
  styleUrls: ['./content-modal.component.scss']
})
export class ContentModalComponent implements OnInit, OnChanges {

  @Input()
  header: string;

  @Input()
  image: string;

  @Input()
  description: string;

  @Input()
  content: string;

  @Input()
  item: any;

  @Input()
  categoryCode: string;

  @Input()
  type = 'game';

  @Output()
  actioned = new EventEmitter<any>();

  @Input()
  contentHash: string;

  @Input()
  buttonText: string;

  @Input()
  isPlayTechClicked = false;

  @Input()
  isGameProviderParent = false;

  @Input()
  gameProviderIndex = 0;

  @Input()
  gameProviderType: string;

  constructor(
    public dialog: MatDialog,
  ) { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (this.content || this.header || this.image) {
      this.dialog.open(ContentDetailsComponent, {
        width: '800px',
        data: {
          header: this.header,
          image: this.image,
          description: this.description,
          content: this.content,
          buttonText: this.buttonText,
          item: this.item,
          categoryCode: this.categoryCode,
          type: this.type,
          isPlayTechClicked: this.isPlayTechClicked,
          isGameProviderParent: this.isGameProviderParent,
          gameProviderType: this.gameProviderType,
          gameProviderIndex: this.gameProviderIndex
        }
      });
    }
  }
}
