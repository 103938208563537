<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="user-input">
    <ng-container *ngIf="!isPerProvider">
      <label>{{ 'Member.Transfer.TransferFrom' | translate }} </label>
      <select [class]="(submitted && !transferToFromValidate) ? 'form-control is-invalid' : 'form-control'" formControlName="transfer_from" (change)="onTransferChange($event)">
        <option [value]="null" disabled>{{ 'Common.DropdownSelect' | translate }}</option>
        <option value="0">{{ 'Member.Transfer.MainWallet' | translate }}</option>
        <ng-container *ngFor="let item of providersDropdown">
          <option [value]="item.id" *ngIf="item.status !== 2">{{ item.name}}</option>
        </ng-container>
      </select>
      <label>{{ 'Member.Transfer.TransferTo' | translate }} </label>
      <select [class]="(submitted && !transferToFromValidate) ? 'form-control is-invalid' : 'form-control'" formControlName="transfer_to" (change)="onTransferChange($event)">
        <option [value]="null" disabled>{{ 'Common.DropdownSelect' | translate }}</option>
        <option value="0">{{ 'Member.Transfer.MainWallet' | translate }}</option>
        <ng-container *ngFor="let item of providersDropdown">
          <option [value]="item.id" *ngIf="item.status !== 2">{{ item.name}}</option>
        </ng-container>
      </select>
      <div *ngIf="submitted && !transferToFromValidate" class="error">Transfer to and transfer from must be different</div>
    </ng-container>

    <label>{{ 'Member.Transfer.TransferAmount' | translate }} </label>
    <input type="text" [class]="(submitted && form.controls.amount.invalid) ? 'form-control is-invalid' : 'form-control'" formControlName="amount" digitOnly decimal="true" decimalSeparator="." pattern="^\d+(\.\d{1,2})?$" style="margin-bottom: 2px;" #focusInput/>
    <div *ngIf="submitted && form.controls.amount.invalid" class="error m-0">This field is required</div>
    <!-- <p class="user-input-msg">Minimum amount: RM10</p> FOR REFERENCE-->
    <div class="amount-select m-b-20 mt-3">
      <a (click)="onChangeAmount(50)">{{ currency?.code }} 50</a>
      <a (click)="onChangeAmount(100)">{{ currency?.code }} 100</a>
      <a (click)="onChangeAmount(300)">{{ currency?.code }} 300</a>
      <a (click)="onChangeAmount(500)">{{ currency?.code }} 500</a>
    </div>

    <div *ngIf="form.value.transfer_to > 0">
      <label> {{ 'Section.Promotion' | translate }} </label>
      <select class="form-control" formControlName="promotion_id">
        <option value="0" selected>{{ 'Member.Transfer.NoPromotion' | translate }}</option>
        <option [value]="item.id" *ngFor="let item of (promotions$ | async)">{{ item.name }}</option>
      </select>
    </div>
  </div>

  <div class="m-t-30">
    <a data-toggle="modal" class="btn-style1" data-target="#system-msg" (click)="submit()">{{ 'Common.Submit' | translate }}</a>
  </div>
</form>

<div class="row m-l-0 m-r-0 m-t-30 all-in-list" *ngIf="!isPerProvider">
  <ng-container *ngFor="let item of providersList">
      <div class="col-all-in all-in-vendor p-l-0 p-r-0" (click)="item.status !== 2 ? onAllIn(item.id) : ''">
      <div *ngIf="item.status === 2" class="img-overlay"></div>
      <img [src]="'assets/images/vendor/' + item.image_path" onerror="this.src='/assets/integrations/default2.jpg'" />
      <p>{{ ( item.balance ? currency?.code + ' ' + (item.balance | number: '1.2-2') : 'N/A')  }} </p>
      <div class="btn-allin" [ngClass]="item.status === 2 ? 'disabled' : ''">
        <a>{{ (item.status !== 2 ? 'Common.AllIn' : 'Common.Maintenance').toString() | translate  }}</a>
      </div>
    </div>
  </ng-container>
</div>

  <app-swal-alert [success]="isSuccess" [message]="messages$ | async" (confirmed)="closeDialog($event)" [contentHash]="contentHash"></app-swal-alert>

