import { EventEmitterService } from '@core/services/event-emitter.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AuthActions } from '@core/store/auth/auth-action.types';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { AppState } from 'app/store/reducers';
import { Injectable } from '@angular/core';

@Injectable()
export class ApiErrorInterceptor implements HttpInterceptor {

  constructor(
    private store: Store<AppState>,
    private loadingBar: LoadingBarService,
    private eventEmitterService: EventEmitterService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
    let message: string;
    const buildMessage = (stack: any[] | string) => {
      let result = '';
      if (stack.length > 0 && typeof stack !== 'string') {
        stack.map((m: any) => {
          if (m !== 'message') {
            result += `<li>${m}</li>`;
          }
        });
      }
      return result;
    };
    return next.handle(request).pipe(
      catchError( (e: HttpErrorResponse) => {

        this.loadingBar.complete();
        this.eventEmitterService.onselectInput();
        const system = e.error;
        message = '<ul>';
        if ((!!system?.errors)) {
          const errors = system.errors;
          Object.keys(errors).forEach(key => message += buildMessage(errors[key]));
        }
        message += buildMessage(system.message);
        message += '</ul>';
        Swal.fire({
          html: '<img class="msg-icon" src="/assets/images/message-icon/exclamation.png">' +
                '<div class="text-center m-t-20">' +
                  '<p class="system-message">Oops!</p>' +
                  message +
                '</div>'
        }).then(() => {
          if (e.status === 403) {
            localStorage.setItem('redirectToLogin', '1');
            this.store.dispatch(AuthActions.logout());
          }
          if (system.message[0] === 'Please verify your mobile phone number'){
            this.eventEmitterService.onOpenVerificationEmitter();
          }
        });
        return throwError(message);
      })
    );
  }
}
