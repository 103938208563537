import { GameProvider } from '../models/game-provider.model';
import { ApiResponse } from '../models/api-response.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class GameProviderHttpService {

  constructor(private http: HttpClient) { }

  getGameBalance(params?: string): Observable<GameProvider[]> {
    return this.http.get<ApiResponse>(`/gameprovider/balance${params}`).pipe(
      map(res => res.data.row)
    );
  }

  launchGame(params?: string): Observable<GameProvider[]> {
    const { isDesktop } = JSON.parse((localStorage.getItem('user_browser')));
    const deviceType = isDesktop ? 'desktop' : 'mobile';
    return this.http.get<ApiResponse>(`/gameprovider/launch${params}&device_type=${deviceType}`).pipe(
      map(res => res.data.row)
    );
  }

}
