import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './subcomponents/about/about.component';
import { TermsConditionsComponent } from './subcomponents/terms-conditions/terms-conditions.component';
import { LiveChatComponent } from './subcomponents/live-chat/live-chat.component';
import { ContactUsComponent } from './subcomponents/contact-us/contact-us.component';
import { GuestComponent } from './content.component';
import { LanguageComponent } from './subcomponents/language/language.component';


const routes: Routes = [
  {
    path: '',
    component: GuestComponent,
    children: [
      {
        path: 'about-us',
        component: AboutComponent,
        data: {title: 'About Us'}
      },
      {
        path: 'terms-conditions',
        component: TermsConditionsComponent,
        data: {title: 'Terms & Conditions'}
      },
      {
        path: 'live-chat',
        component: LiveChatComponent,
        data: {title: 'Live Chat'}
      },
      {
        path: 'contact-us',
        component: ContactUsComponent,
        data: {title: 'Contact Us'}
      },
      {
        path: 'language',
        component: LanguageComponent,
        data: {title: 'Region & Language'}
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContentRoutingModule { }
