import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { isLoggedIn } from '../store/auth/auth.selectors';
import { Store, select } from '@ngrx/store';
import { AppState } from 'app/store/reducers';

@Injectable()

export class SignupGuard implements CanActivate {
  loggedIn: boolean;

  constructor(private store: Store<AppState>, private router: Router) {
    this.store.pipe(
      select(isLoggedIn)
    ).subscribe(loggedIn => this.loggedIn = loggedIn);
  }

  canActivate() {
    if (this.loggedIn){
      return this.router.navigateByUrl('/');
    }else{
      return true;
    }
  }

}
