import { LoadingBarService } from '@ngx-loading-bar/core';
import { Component, OnInit, QueryList, ViewChildren, ElementRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthHttpService } from '@core/services/auth-http.service';
import { Store } from '@ngrx/store';
import { AppState } from '@store/reducers';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as fromAuthActions from '@core/store/auth/auth.actions';
import { AuthRegister } from '@core/models/auth-register.model';
import { tap, delay } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { of } from 'rxjs';

@Component({
  selector: 'app-signup-modal',
  templateUrl: './signup-modal.component.html',
  styleUrls: ['./signup-modal.component.scss']
})
export class SignupModalComponent implements OnInit {

  @ViewChildren('focusInput') focusInput: QueryList<ElementRef>;
  checkBox: boolean = false;
  messages$ = this.auth.messages$;
  isSuccess = this.auth.isSuccess;
  form: FormGroup;
  passwordInput = {
    isMasked: true,
    type: null,
    icon: null,
    isValidPassword: true
  };
  passwordMatched = true;

  constructor(
    public dialogRef: MatDialogRef<SignupModalComponent>,
    private router: Router,
    private auth: AuthHttpService,
    private store: Store<AppState>,
    private fb: FormBuilder,
    public loadingBar: LoadingBarService,
  ) { }

  ngOnInit() {
    this.onPasswordMask();
    this.initializeForm();
    of(null).pipe(
        delay(200), tap(() => this.focusInput.first.nativeElement.focus()
    )).subscribe();
  }

  onCloseDialog(event?: Event ) {
    this.dialogRef.close();
  }

  onPasswordMask() {
    this.passwordInput.isMasked = !this.passwordInput.isMasked;
    this.passwordInput.type = this.passwordInput.isMasked ? 'text' : 'password';
    this.passwordInput.icon = this.passwordInput.isMasked ? 'icon-eye' : 'icon-eye-slash';
  }

  onCheckPassword(){
    this.passwordMatched = true;
    if (this.form.value.confirm_password !== null && this.form.value.password !== null){
      if(this.form.value.password !== this.form.value.confirm_password){
        this.passwordMatched = false;
      }

      if (this.form.value.password.length === 0 || this.form.value.confirm_password.length === 0){
        this.passwordMatched = true;
      }
    }
  }

  onRedirectLogin() {
    this.router.navigate(['/login']);
  }

  submit(value: AuthRegister) {
    if (this.form.value.confirm_terms) {
      this.loadingBar.start();
      this.auth.register(value).pipe(
        tap( () => {
          this.store.dispatch(fromAuthActions.register());
          this.router.navigate(['/login']);
          this.onCloseDialog();
          this.loadingBar.complete();
        })
      )
      .subscribe(() =>  this.isSuccess = this.auth.isSuccess);
    }else{
      Swal.fire({
        html: '<i class="icon-exclamation-triangle alert-icon-fail"></i>' +
              '<div class="text-center m-t-20">' +
                '<ul><li>Please agree on Terms & Conditions</li></ul>' +
              '</div>',
          confirmButtonText: 'Close',
      });
   }
  }

  onCheck(){
    if( this.checkBox === true){
      this.checkBox = false;
      this.onAgree();
    }else{
      this.checkBox = true;
      this.onAgree();
    }
  }

  onAgree() {
    this.form.patchValue({
      confirm_terms : this.form.value.confirm_terms ? null : 1
    });
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.auth.forceLowerCaseInputControl(this.form, controlName, event);
  }

  private initializeForm() {
    this.form = this.fb.group({
      username: [
        null,
        [Validators.required]
      ],
      phone_number: [
        null,
        [Validators.required]
      ],
      confirm_terms: [
        null,
        [Validators.required]
      ],
      password: [
        null,
        [Validators.required]
      ],
      confirm_password: [
        null,
        [Validators.required]
      ]
    });
  }

}
