import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { isLoggedIn, loggedUser } from '@core/store/auth/auth.selectors';
import { Store, select } from '@ngrx/store';
import { AppState } from '@store/reducers';
import { AuthHttpService } from '@core/services/auth-http.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {


  languages = this.dropdownHttpService.languageCode;
  countries = this.dropdownHttpService.countryCode;
  languageCode = '';
  countryCode = '';
  deviceType: string;
  sessionIdTracker = '';

  constructor(
    private dropdownHttpService: DropdownHttpService,
    private store: Store<AppState>,
    private authService: AuthHttpService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    const apiBase: string = environment.apiBase;
    const isExternal: boolean = request.url.startsWith('//');
    const userToken = localStorage.getItem('user_token');
    this.sessionIdTracker = localStorage.getItem('sessionid_tracker');

    const {
      access_token,
      plaintext_token,
      expiry_datetime
    }: any = JSON.parse(userToken) || { access_token: undefined, plaintext_token: undefined };

    const {
      userIP,
      userAgent
    }: { userIP: string; userAgent: string }
    = JSON.parse(localStorage.getItem('user_browser')) || { userIP: undefined, userAgent: undefined, expiry_datetime: Date };

    if (access_token && !isExternal) {
      request = request.clone({
        headers: request.headers
          .append('Authorization', `Bearer ${access_token}`)
          .append('access-token', `${access_token}`)
          .append('token-selector', `${plaintext_token}`)
        }
      );
    }

    if ((userIP && userAgent) && !isExternal) {
      if (!localStorage.getItem('language_code') && !localStorage.getItem('country_code')){
        this.store.pipe(select(isLoggedIn)).subscribe(res => {
          if (res){
            this.store.pipe(select(loggedUser)).subscribe(user => {
              this.languageCode = user.lang_code;
              this.countryCode = user.country_code;
              localStorage.setItem('language_code', user.lang_code);
              localStorage.setItem('country_code', user.country_code);
            });
          } else {
            this.languageCode = this.languages[1];
            this.countryCode = this.countries[1];
          }
        });
      } else {
        this.languageCode = localStorage.getItem('language_code');
        this.countryCode = localStorage.getItem('country_code');
      }

      if (JSON.parse(localStorage.getItem('user_browser')).isDesktop) {
        this.deviceType = 'Desktop';
      } else {
        this.deviceType = 'Mobile';
      }

      request = request.clone({
        headers: request.headers
          .append('X-User-IP', userIP)
          .append('X-User-Agent', userAgent)
          .append('X-Language-Code', this.languageCode)
          .append('X-Country-Code', this.countryCode)
          .append('X-Device-Type', this.deviceType)
          .append('X-Site-Prefix', environment.sitePrefix)
          .append('X-Session-ID', this.sessionIdTracker)
        }
      );
    }

    // Check entry
    this.authService.checkTokenExpiration(expiry_datetime);

    request = request.clone({
      url: isExternal ? request.url : apiBase + request.url
    });

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }),
      finalize(() => { })
    );

  }
}
