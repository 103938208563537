import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';

@Injectable()
export class DashboardService {

  memberBalance$ = new Subject<number>();
  memberUsername$ = new Subject<string>();

  reloadProviderEmmiter = new EventEmitter();
  subsVar: Subscription;

  constructor() { }

  refreshMemberBalance(amount: number) {
    this.memberBalance$.next(amount);
  }

  reloadProvider() {
    this.reloadProviderEmmiter.emit();
  }

  refreshMemberName(username: string) {
    this.memberUsername$.next(username);
  }

}
