import { Banner } from './../models/banner.model';
import { ApiResponse } from '../models/api-response.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class BannerHttpService {

  constructor(private http: HttpClient) { }

  getAllBanner(): Observable<Banner[]> {
    return this.http.get<ApiResponse>(`/banner?type=1`).pipe(
      map(res => res.data)
    );
  }

}
