import { EventEmitterService } from '@core/services/event-emitter.service';
import { Component, OnInit, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, NavigationStart, RouteConfigLoadStart, RouteConfigLoadEnd, NavigationCancel } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { AppState } from './store/reducers';
import { changeProfile, login } from '@core/store/auth/auth.actions';
import { filter, tap, delay, map, concatMap } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { Subscription, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthHttpService } from '@core/services/auth-http.service';
import { IpHttpService } from '@core/services/ip-http.service';
import { environment } from '@env/environment';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { isLoggedIn, loggedUser } from '@core/store/auth/auth.selectors';
import { OnDestroy } from '@angular/core';
import { PortalMemberProfileHttpService } from '@core/services/portal-member-profile-http.service';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  siteName = 'BP77';
  pageTitle = '';
  isLanding: boolean;
  isDesktopDetected = false;

  private unsubscribe: Subscription[] = [];

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private deviceDetectorService: DeviceDetectorService,
    private authService: AuthHttpService,
    private ipService: IpHttpService,
    public loadingBar: LoadingBarService,
    private eventEmitterService: EventEmitterService,
    private memberProfileService: PortalMemberProfileHttpService
  ) {}

  ngOnInit() {
    this.browserInit();
    const languageCode = localStorage.getItem('language_code');
    if (languageCode !== null) {
      this.translateService.use((languageCode).toLocaleLowerCase());
    }
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
      this.getRouteTitle(this.activatedRoute).data
      .pipe(tap(data => this.pageTitle = data.title))
      .subscribe(() => {
        this.titleService.setTitle(`${this.pageTitle} | ${this.siteName}`);
        this.isLanding = this.router.url === '/landing';
      });
    });
    const userData = localStorage.getItem('user_data');
    const userToken = localStorage.getItem('user_token');
    if ((userData !== null && userToken !== null) && (userData && userToken)) {
      this.store.dispatch(login({ user: JSON.parse(userData), token: JSON.parse(userToken) }));
    }

  }

  ngAfterViewInit() {

    setTimeout(() => {
      const userData = JSON.parse(localStorage.getItem('user_data'));
      if( userData && userData.id) {
        if(!this.authService.isTokenExpired()) {
          const userProfileSub = this.memberProfileService.getProfile().pipe(
            tap(user => {
              if( user.name !== null || user.name.length > 0) {
                this.store.dispatch(changeProfile({
                  profile: {
                    id: user.id,
                    name: user.name,
                    member_group: {
                      name: user.member_group.name,
                      image: user.member_group.image
                    }
                  }
                }))
              }
            })
          ).subscribe();
          this.unsubscribe.push(userProfileSub);
        }
      }
    }, 1000)

  }

  ngOnDestroy() {
    this.unsubscribe.map(sb => sb.unsubscribe)
  }

  onClose(){
    this.router.navigate(['/']);
  }

  onHideSideBar(){
    this.eventEmitterService.onRemoveBodyClass();
  }

  private preloaderInit() {
    // Percentage just in case needed!
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.loadingBar.start();
      }
      if (event instanceof RouteConfigLoadStart) {
        this.loadingBar.increment(35);
      }
      if (event instanceof RouteConfigLoadEnd) {
        this.loadingBar.increment(75);
      }
      if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        this.loadingBar.complete();
      }
    });
  }

  private browserInit() {
    if (this.deviceDetectorService.isDesktop()) {
      this.isDesktopDetected = true;
      const marketingParams = this.authService.getUrlParams(window.location.search);
      let authSuffix = '';
      if ((window.location.pathname).startsWith('/signup') && Object.keys(marketingParams)) {
        authSuffix = `/signup?${ Object.keys(marketingParams).map(key => key + '=' + marketingParams[key]).join('&') }`;
      }
      window.location.href = environment.desktopDomain + authSuffix;
    }
    const browserSub = this.ipService.getBrowserInfo().subscribe(res => {
      localStorage.setItem('user_browser', JSON.stringify(res));
      localStorage.setItem('sessionid_tracker', (+new Date()).toString(16).toUpperCase());
    });
    this.unsubscribe.push(browserSub);
  }

  private getRouteTitle(activatedRoute: ActivatedRoute): ActivatedRoute {
    if (activatedRoute.firstChild) {
      return this.getRouteTitle(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }
}
