import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {tap, switchMap, delay, catchError} from 'rxjs/operators';
import { AuthActions } from './auth-action.types';
import { AuthHttpService } from '@core/services/auth-http.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthEffects {

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.login),
      tap(action => {
        localStorage.setItem('user_data', JSON.stringify(action.user));
        localStorage.setItem('user_token', JSON.stringify(action.token));
        localStorage.setItem('language_code', action.user.lang_code);
        localStorage.setItem('country_code', action.user.country_code);
      }
    )),
    { dispatch: false });

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logout),
      tap( () => {
        // Note: Don't clear sessionStorage!
        localStorage.removeItem('user_token');
        localStorage.removeItem('user_data');
        localStorage.removeItem('profile');

        // Note: Native redirect to destroy browser!
        if(+JSON.parse(localStorage.getItem('redirectToLogin')) === 1) {
          window.location.href = '/login';
        } else {
          window.location.href = '/';
        }
      })
    ),
    { dispatch: false });

    constructor(
      private router: Router,
      private actions$: Actions,
      private authService: AuthHttpService
    ) { }

}
