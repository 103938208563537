import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { GameCategory } from '../../models/games/game-category.model';

@Injectable()
export class GameCategoryEntityService extends EntityCollectionServiceBase<GameCategory> {
    constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
        super('GameCategory', serviceElementsFactory);
    }
}

