import { Component, OnInit } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { ForgotPasswordHttpService } from '@core/services/forgot-password-http.service';
import { Router } from '@angular/router';
import { AuthHttpService } from '@core/services/auth-http.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  form: FormGroup;

  messages$ = this.forgotPasswordHttpService.messages$;
  isSuccess = this.forgotPasswordHttpService.isSuccess;

  constructor(
    public loadingBar: LoadingBarService,
    private router: Router,
    private auth: AuthHttpService,
    public forgotPasswordHttpService: ForgotPasswordHttpService) { }

  ngOnInit(): void {
    this.initializeForm();
    document.getElementById('focusInput').focus();
  }

  submit() {
    this.loadingBar.start();
    const data = {
      ...this.form.value
    };
    this.forgotPasswordHttpService.forgotPassword(data).subscribe( () =>{
      this.isSuccess = this.forgotPasswordHttpService.isSuccess;
      this.loadingBar.complete();
    });
  }


  onRedirectLogin() {
    this.router.navigate(['/login']);
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.auth.forceLowerCaseInputControl(this.form, controlName, event);
  }

  private initializeForm() {
    this.form = new FormGroup({
      username: new FormControl(null, [Validators.required]),
      phone_number: new FormControl(null, [Validators.required])
    });
  }
}
