
import { Injectable } from '@angular/core';
import { lang } from 'moment';
import { DropdownHttpService } from './dropdown-http.service';

@Injectable()
export class LiveChatIdHttpService {

  constructor(
    private dropdownHttpService: DropdownHttpService
  ){}

  id: number;
  url = 'https://direct.lc.chat/10515072/';

  getUrl(country: string, language: string){
    if (country === this.dropdownHttpService.countryCode[1]) {
      if (language === this.dropdownHttpService.languageCode[1]){
        this.id = 1;
      } else if (language === this.dropdownHttpService.languageCode[2]){
        this.id = 2;
      } else if (language === this.dropdownHttpService.languageCode[3]){
        this.id = 3;
      }
    } else if (country === this.dropdownHttpService.countryCode[2]){
      if (language === this.dropdownHttpService.languageCode[4]){
        this.id = 6;
      } else if (language === this.dropdownHttpService.languageCode[1]){
        this.id = 7;
      }
    } else if (country === this.dropdownHttpService.countryCode[3]){
      if (language === this.dropdownHttpService.languageCode[1]){
        this.id = 4;
      } else if (language === this.dropdownHttpService.languageCode[2]){
        this.id = 5;
      }
    }
    return this.url + this.id;
  }

}
